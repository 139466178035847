//Masters
import NasList from "../pages/radius/nas";
import AddNas from "../pages/radius/nas/add";
import EditNas from "../pages/radius/nas/edit";
import IppoolList from "../pages/radius/ippool";
import AddIppool from "../pages/radius/ippool/add";
import EditIppool from "../pages/radius/ippool/edit";
import PlanList from "../pages/radius/plan";
import AddPlan from "../pages/radius/plan/add";
import EditPlan from "../pages/radius/plan/edit";
import AddonList from "../pages/radius/addon";
import AddAddon from "../pages/radius/addon/add";
import EditAddon from "../pages/radius/addon/edit";
import AssignedPlan from "../pages/radius/assigned_plan";
import AddNasGroup from "../pages/radius/nas_group/add";
import EditNasGroup from "../pages/radius/nas_group/edit";
import NasGroupList from "../pages/radius/nas_group";
import AddPlanGroup from "../pages/radius/plan_group/add";
import EditPlanGroup from "../pages/radius/plan_group/edit";
import PlanGroupList from "../pages/radius/plan_group";
//Composers
import { NasComposer } from "../composers/NasComposer";
import { IppoolComposer } from "../composers/IppoolComposer";
import { PlanComposer } from "../composers/PlanComposer";
import { AddonComposer } from "../composers/AddonComposer";
import { AssignedPlanComposer } from "../composers/AssignedPlanComposer";
import { NasGroupComposer } from "../composers/NasGroupComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import { PlanGroupComposer } from "../composers/PlanGroupComposer";
// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import ProtectedLayout from "../layouts/ProtectedLayout";

const RadiusRoutes = {
  path: "radius",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  menuPermission: ["radius"],
  children: [
    {
      path: "nas",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <NasComposer>
                <AddNas />
              </NasComposer>
            </ProtectedLayout>
          ),
          menuPermission: ["nas", "add"],
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <NasComposer>
                <EditNas />
              </NasComposer>
            </ProtectedLayout>
          ),
          menuPermission: ["nas", "edit"],
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <NasComposer>
                <NasList />
              </NasComposer>
            </ProtectedLayout>
          ),
          menuPermission: ["nas", "list"],
        },
      ],
      menuPermission: ["nas"],
    },
    {
      path: "nas-group",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <NasGroupComposer>
                <AddNasGroup />
              </NasGroupComposer>
            </ProtectedLayout>
          ),
          menuPermission: ["nas-group", "add"],
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <NasGroupComposer>
                <EditNasGroup />
              </NasGroupComposer>
            </ProtectedLayout>
          ),
          menuPermission: ["nas-group", "add"],
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <NasGroupComposer>
                <NasGroupList />
              </NasGroupComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "ippool",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <IppoolComposer>
                <AddIppool />
              </IppoolComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <IppoolComposer>
                <EditIppool />
              </IppoolComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <IppoolComposer>
                <IppoolList />
              </IppoolComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "plan",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <PlanComposer>
                <AddPlan />
              </PlanComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <PlanComposer>
                <EditPlan />
              </PlanComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <PlanComposer>
              <ProtectedLayout>
                <PlanList />
              </ProtectedLayout>
            </PlanComposer>
          ),
        },
      ],
    },
    {
      path: "plan-group",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <PlanGroupComposer>
                <AddPlanGroup />
              </PlanGroupComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <PlanGroupComposer>
                <EditPlanGroup />
              </PlanGroupComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <PlanGroupComposer>
                <PlanGroupList />
              </PlanGroupComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "addon",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <AddonComposer>
                <AddAddon />
              </AddonComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <AddonComposer>
                <EditAddon />
              </AddonComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <AddonComposer>
                <AddonList />
              </AddonComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "assigned-plan",
      element: (
        <ProtectedLayout>
          <AssignedPlanComposer>
            <AssignedPlan />
          </AssignedPlanComposer>
        </ProtectedLayout>
      ),
    },
  ],
};

export default RadiusRoutes;
