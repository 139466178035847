import { OtpConstants } from "../constants/otp.constants";

const OtpReducer = (state, action) => {
  switch (action.type) {
    case OtpConstants.FETCH_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case OtpConstants.FETCH_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otps: action.payload.data,
        paging: action.payload.paging,
      };
    case OtpConstants.FETCH_OTP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case OtpConstants.OTP_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case OtpConstants.OTP_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpSearchResults: action.payload,
      };
    case OtpConstants.OTP_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case OtpConstants.OTP_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case OtpConstants.OTP_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpAutoSearchResults: action.payload,
      };
    case OtpConstants.OTP_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case OtpConstants.ADD_OTP_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case OtpConstants.ADD_OTP_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case OtpConstants.ADD_OTP_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case OtpConstants.GET_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case OtpConstants.GET_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otp: action.payload,
      };
    case OtpConstants.GET_OTP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case OtpConstants.UPDATE_OTP_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case OtpConstants.UPDATE_OTP_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        otp: action.payload,
      };
    case OtpConstants.UPDATE_OTP_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case OtpConstants.DOWNLOAD_OTP_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case OtpConstants.DOWNLOAD_OTP_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case OtpConstants.DOWNLOAD_OTP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case OtpConstants.UPLOAD_OTP_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case OtpConstants.UPLOAD_OTP_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case OtpConstants.UPLOAD_OTP_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default OtpReducer;
