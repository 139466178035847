//Users
import OtpList from "../pages/reports/otp_reports/index.js";

//Composers
import OtpComposer from "../composers/OtpComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout.js";

const OtpRoutes = {
  path: "otp",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "list",
      element: (
        // <ProtectedLayout>
        <OtpComposer>
          <OtpList />
        </OtpComposer>
        // </ProtectedLayout>
      ),
    },
  ],
};

export default OtpRoutes;
