import React, { useEffect, useState, useRef } from "react";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";

import { Card, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../../utils/windowDimensions";
import useUser from "../../../../hooks/useUser";
import useAuth from "../../../../hooks/useAuth";
import { formatDate, addDays } from "../../../../utils/common";
import EditDelete from "../../../../components/EditDelete";
import RenewUser from "../../../users/userlist/components/RenewUser";
import { getTimeStamp } from "../../../../utils/common";
import { useDialog } from "../../../../contexts/DialogContext";
import useMediaQuery from "@mui/material/useMediaQuery";

function Tomorrow({ setTomorrowCount }) {
  const { user } = useAuth();
  console.log(user, "llllllllllllll");
  const { getAllUser, users: expiredTomorrowUsers, paging, isLoading } = useUser();
  const { width: mainContentWidth } = useMainContentDimensions();
  const { unSetDialog, openDialog } = useDialog();
  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;

  const currentPayload = {
    filters: [
      {
        fieldName: "expiration",
        fieldValue:
          formatDate(addDays(new Date(), 1)) +
          " 00:00:00," +
          formatDate(addDays(new Date(), 1)) +
          " 23:59:59",
        op: "btn",
        dataType: "string",
        condition: "and",
        isJoin: false,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const isTablet = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(min-width:1025px) and (max-width:1440px)");

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    if (Object.keys(payload).length != 0) {
      await getAllUser(payload);
    }
  };

  const renewClick = (id) => {
    return openDialog(
      "renew_user",
      "Renew User",
      <RenewUser key={getTimeStamp()} userId={id} />,
      "md",
    );
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete
            rowClick={onClick}
            renewClick={() => renewClick(params.id)}
            menus={["renew"]}
          />
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      renderCell: (params) => <Link to={"/user/details?id=" + params.id}>{params.value}</Link>,
      width: 150,
    },
    {
      field: "fullName",
      headerName: "Customer Name",
      width: 150,
    },
    {
      field: "expiration",
      headerName: "Expiry Date",
      width: 180,
    },
    {
      field: "mobileNo",
      headerName: "Mobile",
      width: 100,
    },
    {
      field: "tenantName",
      headerName: "Franchise Name",
      width: 200,
    },
    {
      field: "planName",
      headerName: "Plan",
      width: 200,
    },
  ];

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  useEffect(() => {
    setTomorrowCount(paging.totalNumberOfRecords);
  }, [paging]);

  return (
    <Card sx={{ width: isTablet ? mainContentWidth - 50 : mainContentWidth - 600 }}>
      <CardContent sx={{ height: isTablet ? height - 100 : height - 200 }}>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          columns={columns}
          rows={expiredTomorrowUsers}
          refreshPage={fetchData}
          getRowId={(row) => row["radUserId"]}
          rowCount={paging?.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging?.pageNumber}
          pageSize={5}
          paginationMode="server"
          onPaginationModelChange={onPageChange}
          paginationMeta={paginationMeta}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
        />
      </CardContent>
    </Card>
  );
}

export default Tomorrow;
