import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import OtpReducer from "../reducers/OtpReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { OtpConstants } from "../constants/otp.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  otps: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  otpSearchResults: [],
  otp: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  otpAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const OtpContext = createContext({
  ...initialState,
  getAllOtp: () => {},
  addOtp: () => {},
  searchOtp: () => {},
  autoSearchOtp: () => {},
  getOtp: () => {},
  updateOtp: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function OtpProvider({ children }) {
  const [state, dispatch] = useReducer(OtpReducer, initialState);

  const getAllOtp = async (payload) => {
    try {
      dispatch({
        type: OtpConstants.FETCH_OTP_REQUEST,
      });
      const response = await axios.post("/auth/otp/list", payload);
      dispatch({
        type: OtpConstants.FETCH_OTP_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: OtpConstants.FETCH_OTP_FAILURE,
      });
    }
  };
  const searchOtp = async (payload) => {
    try {
      const response = await axios.post("/auth/otp/search", payload);
      dispatch({
        type: OtpConstants.OTP_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: OtpConstants.OTP_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: OtpConstants.OTP_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchOtp = async (payload) => {
    try {
      const response = await axios.post("/auth/otp/auto-search", payload);
      dispatch({
        type: OtpConstants.OTP_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: OtpConstants.OTP_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: OtpConstants.OTP_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addOtp = async (payload) => {
    const response = await axios.post("/auth/otp/add", payload);
    dispatch({
      type: OtpConstants.ADD_OTP_REQUEST,
    });
    dispatch({
      type: OtpConstants.ADD_OTP_SUCCESS,
      payload: response.data,
    });
  };

  const getOtp = async (id) => {
    const response = await axios.get("/auth/otp/" + id);
    dispatch({
      type: OtpConstants.GET_OTP_REQUEST,
    });
    dispatch({
      type: OtpConstants.GET_OTP_SUCCESS,
      payload: response.data,
    });
  };

  const updateOtp = async (payload) => {
    const response = await axios.put("/auth/otp/update/" + payload.otpId, payload);
    dispatch({
      type: OtpConstants.UPDATE_OTP_REQUEST,
    });
    dispatch({
      type: OtpConstants.UPDATE_OTP_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: OtpConstants.DOWNLOAD_OTP_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/auth/otp/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "otp." + fileType);
    dispatch({
      type: OtpConstants.DOWNLOAD_OTP_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/auth/otp/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_otp." + fileType);
    dispatch({
      type: OtpConstants.DOWNLOAD_OTP_REQUEST,
    });
    dispatch({
      type: OtpConstants.DOWNLOAD_OTP_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: OtpConstants.UPLOAD_OTP_REQUEST,
      });
      const response = await axios({
        url: "/auth/otp/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: OtpConstants.UPLOAD_OTP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: OtpConstants.UPLOAD_OTP_FAILURE,
      });
    }
  };

  return (
    <OtpContext.Provider
      value={{
        ...state,
        getAllOtp,
        addOtp,
        searchOtp,
        autoSearchOtp,
        getOtp,
        updateOtp,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </OtpContext.Provider>
  );
}

export { OtpContext, OtpProvider };
