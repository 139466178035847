import React from "react";
import styled from "@emotion/styled";
import { Chart } from "react-chartjs-2";
import { useTheme } from "@mui/material/styles";
import { MoreVertical } from "react-feather";

import { green, red, orange } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Chip,
  Box,
  Grid,
  TableContainer,
  Paper,
} from "@mui/material";
import { borderBottom, spacing } from "@mui/system";
import useScheduleUser from "../../../hooks/useScheduleUser";
import { formatBytes, convertMBToBytes } from "../../../utils/common";
import { convertSecondToDays } from "../../../utils/common";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const CafTerms = ({ user }) => {
  const { activeScheduleUser } = useScheduleUser();
  const theme = useTheme();
  const CardContent = styled(MuiCardContent)`
    &:last-child {
      padding-bottom: ${theme.spacing(2)};
    }
  `;
  console.log(user, "user");
  const data = {
    labels: ["Social", "Search Engines", "Direct", "Other"],
    datasets: [
      {
        data: [260, 125, 164],
        backgroundColor: [theme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const userStatus = (status) => {
    if (status == "active") {
      return <Chip label="Active" color="primary" />;
    } else if (status == "online") {
      return <Chip label="Online" color="success" />;
    } else if (status == "suspended") {
      return <Chip label="Suspended" sx={{ backgroundColor: "rgb(211, 47, 47)", color: "#fff" }} />;
    } else if (status == "on_hold") {
      return <Chip label="On Hold" sx={{ backgroundColor: "rgb(255, 222, 33)" }} />;
    } else if (status == "expired") {
      return <Chip label="Expired" color="warning" />;
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card mb={6} sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      1. SERVICE PROVIDER means the Broadband Internet Access Service offered by
                      BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. having its registered office at BLUE
                      LOTUS SUPPORT SERVICES PRIVATE LTD. 18/7,1" Floor, Postal Colony Third Street,
                      West Mambalam, Chennai 600033 and its Local Service Franchise
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      2. BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. is providing service till Local
                      service Franchisee's nearest BTS of Telecom Companies and Local Service
                      Franchise providing service from their Control Room till customer end through
                      their network.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      3. Service Provider in general included but not limited to Internet Access,
                      E-mail, world wide web Broadband Internet Telephony, Designing Hosting,
                      International Roaming and other information Services that SERVICE PROVIDER
                      offers, markets, maintains and supports as of data and all updates and upgrade
                      that may offer to the customer. However service in relation to the customer
                      shall mean and cover only those service, which the customer has specifically
                      availed
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      4. The terms and conditions herein, acceptable usage policy and responsible
                      usage policy, published by Service Provider from time to time shall constitute
                      and will be an integral part of the agreement between SERVICE PROVIDER and
                      customer with respect to the service, SERVICE PROVIDER reserves the right to
                      change any or all aspects of this agreement operating rules, usage guideline,
                      prices governing the service, service plans the pricing structure or
                      product/service policies at any time without prior intimation to the customer.
                      The customer acknowledges that it is his/here sole responsibility to appraise
                      himself/herself of the Terms and conditions and responsible usage policy, as
                      amended form time to time and agree to abide by the same.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      5. Every subscriber registration must be done with complete and correct
                      details sun as name, address, telephone number and e-mail address etc.., in
                      case of any change the same should be updated by the respective subscriber
                      without fail or delay
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      6. The service is being provided subject to all the applicable Indian Laws,
                      rules and regulations.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      7. SERVICE PROVIDER shall provide service to the customer in accordance with
                      the terms and conditions then in force and as amended form time to time, till
                      the expiry of the specified period for which customer has paid for
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      8. The service charge is payable in advance and is non- refundable. All taxes,
                      duties, charges or any other levies of any nature whatsoever, payable for the
                      service shall be payable by the customer in addition to the service charges.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      9. SERVICE PROVIDER will put its best effort and strive to maintain the
                      maximum possible uptime of the service. However SERVICE PROVIDER will not be
                      responsibility for downtime of service due to reasons beyond its control
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      10. The customer also acknowledge and accepts that in the very nature of the
                      service to be provided there can be number of factors affecting the provision
                      of the service by SERVICE PROVIDER and the obligation of SERVICE PROVIDER to
                      provide the services shall be on best endeavor basis
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      11. SERVICE PROVIDER shall not be liable to the Customer for any loss,
                      exprises or damage or any kind in connection with the performance of its
                      obligation under this contract or arising from disruption, interruption,
                      suspension or manufacture of the service, for whatsoever reason including
                      force Majeure except when there is deliberate failure or breach on the part of
                      SERVICE PROVIDER.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      12.All third party software that may be provided with the EASY Surf CD is
                      included for use at the customer's option. SERVICE PROVIDER shall not be
                      responsible for any losses or damages which may occur resulting form the use
                      of any third party software. Any support that is needed has to be availed form
                      the respective companies
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      13. SERVICE PROVIDER will be responsibility only for carrying data packets and
                      is not responsible for its nature or content. Customer guarantees that the
                      software at his end.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      14. It is explicitly agreed that SERVICE PROVIDER does not undertake any
                      responsibility with regard to procuring / installing / maintaining the
                      hardware and / or software at the customers end
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      15. The Ethernet Card installed by in customer's Pc has been done purely on
                      request basis and SERVICE PROVIDER will not responsible for any failure of the
                      Card. Further SERVICE PROVIDER will not be responsible for the problems
                      related to Customers PC and the software loaded on it.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      16.Customer hereby undertakes to indemnify and hold harmless SERVICE PROVIDER
                      against liability which may arise for any and all, acts on the part of the
                      customer in availing the service.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      17. The customer shall take full and sole responsibility for preserving the
                      secrecy of the password. The customer acknowledge and accept the considering
                      the nature of the service there will be a need to change the password from
                      time to time to avoid misuse and to maintain secrecy SERVICE PROVIDER shall
                      not be responsible for any wrongful or unauthorized use, under any
                      circumstance
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      18. The service to be provided shall be exclusively to the customer. The
                      customer shall not transfer, reassign, sell and / or offer or promote the
                      service to others or affiliates of the customer. Customer shall not do
                      anything that is detrimental to SERVICE PROVIDER interests.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      19.BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. shall have the right to terminate
                      the services without any prior notice to the customer, in the event of breach
                      of these terms and conditions on the part of the customer, SERVICE PROVIDER
                      and the shall also have the right to terminate this Agreement at any time by
                      giving 30 days prior notice in writing to the other and without the need to
                      assign any reason for such termination. In the event of such termination by
                      SERVICE PROVIDER the customer shall be entitled to the return of the
                      proportionate part of the charges paid for the unutilized period of service.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Card mb={6} sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      20.SERVICE PROVIDER shall not be liable to refund any amount if the
                      termination is by the customer or for breach or failure on the part of the
                      customer. SERVICE PROVIDER shall endeavor to provide the best of its service
                      to the customer through a dedicated customer care service team. The customer
                      shall in all communication to Kings including when interacting with its
                      employees, associates and partner, maintain decorum, decency and shall refrain
                      form using any threatening, unparliamentarily, abusive language, SERVICE
                      PROVIDER reserve the right to termination or disconnect such customers with an
                      immediate Effect if found using such language or disconnect such customers
                      with an immediate Effect if found using such language
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      21. The customer acknowledges that the service is provided as is SERVICE
                      PROVIDER its employees agents, suppliers vendors and distributors make no
                      warranty of any kind, either expressed or implied regarding the quality,
                      accuracy, or validity of the data and / or information available on its
                      systems, or residing on or passing through its interrupted networks, or that
                      the service will be uninterrupted or error free. SERVICE PROVIDER expressly
                      excludes any implied warranty of merchantability
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      22. BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. and the customer hereby agree
                      that this agreement together with the operational rules, Acceptable Usage
                      Policy and usage guidelines and other terms notified by SERVICE PROVIDER shall
                      constitute the complete terms and conditions between them and there shall be
                      no addition modification or deletion to these terms and conditions unless it
                      is to agreed in writing and signed by both SERVICE PROVIDER and the customer.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      23. Telephony on Internet is permitted to a limited extent i.e. (i) form PC to
                      PC within or outside India (ii) in India to Telephone outside India (iii) IP
                      based H.323/SIP Terminals connected directly to SIP nodes to similar Terminals
                      within or outside India. The customers shall strictly comply with the above
                      and the persons involved are liable to be proceeded with and punished
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      24. It is illegal to terminate internet Telephony calls
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      25. The customer agrees that BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. shall
                      not be liable for Service downgrades where the last mile connectivity is last
                      provided by BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. The responsibility of
                      ensuring last mile connectivity shall be on the last mile connectivity network
                      provided like cable TV operator DID etc. and BLUE LOTUS SUPPORT SERVICES
                      PRIVATE LTD. Responsibility shall be limited to ensure service till the point
                      of presence of the last mile network operator.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      26. SERVICE PROVIDER entire liability and the customer's exclusive remedy for
                      any failure or reach on the part of the charges paid by the customer relating
                      the unexpired period of the use. SERVICE PROVIDER disclaims all warranties and
                      conditions express or implied including but not limited to implied warranties
                      or conditions of merchantability fitness for a particular purpose with regard
                      to the service offered and in no event SERVICE PROVIDER shall be liable for
                      any other damages including special indirect or consequential damages loss of
                      profits business interruption whatsoever arising out of use or inability to
                      use the service
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      27. In using the service the customer agrees to comply with all laws,
                      regulations and rule applicable and hereby indemnifies SERVICE PROVIDER
                      against any claims, loss damage or consequence arising from non compliance of
                      the customer to any applicable laws, rules, regulations etc.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      28. BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. will not be liable for any act of
                      the LOCAL SERVICE PROVIDER managing the service at customers end outside the
                      scope of this service. The customer expressly acknowledges that the liability
                      of BLUE LOTUS SUPPORT SERVICES PRIVATE LTD. is limited to the service rendered
                      by BLUE LOTUS SUPPORT SERVICES PRIVATE LTD, and BLUE LOTUS SUPPORT SERVICES
                      PRIVATE LTD. shall not be liable for any acts deeds of the LOCAL SERVICE
                      FRANCHISEE.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      29.The customer hereby declares that he has read these terms and conditions
                      and the order from completely and that he unconditionally agrees to abide by
                      these terms and conditions, and as is applicable from time to time.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      30. The backbone i-e the cable, switches, etc. installed by SERVICE PROVIDER
                      is the exclusive property of SERVICE PROVIDER and customer shall not remove /
                      relocate these without prior consent from SERVICE PROVIDER
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      31. The service is exclusively for Home usage and not for commercial usage.
                      Usage for more than 6 hours per day shall be commercial usage. SERVICE
                      PROVIDER reserve the rights to disconnect the service without prior
                      intimation, if the usage is found to be commercial.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      32. User acknowledges that spamming of e-mails is prohibited spamming is the
                      transmission of any form of mail that can be interpreted as junk mail or mail
                      generated via a distribution list which the recipient has not specifically
                      requested. The user can transmit only a fixed number of out going mails as
                      part of SERVICE PROVIDER mail policy and a violation of this will by
                      considered as spamming. SERVICE PROVIDER reserves the right to block the
                      user's mail id or even terminate the service if this is violated by any user.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      33. The speeds mentioned against various service plans are as experienced
                      within the SERVICE PROVIDER network.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      34. For enhanced security, the SERVICE PROVIDER user ID as choose by the user
                      at the time of registration, the IP address and the MAC address (unique for
                      each LAN card) will be tied to each other. This means that a User ID can be
                      used only form that user's PC and IP address allocated to the user. However it
                      is possible for the user to have multiple user ids, with different service
                      plans from the same PC.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
                      35.The customer shall not use the service provided by SERVICE PROVIDER for any
                      illegal.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          borderBottom: "1px solid #ccc",
          mb: 6,
          borderRadius: "0px",
        }}
      >
        <Table size="small">
          <TableBody>
            {/* Row 1 */}
            <TableRow>
              <TableCell
                align="right"
                variant="h3"
                sx={{
                  padding: "16px",
                  width: "100%", // Set 80% width for column 2
                }}
              >
                Signature of Customer<span style={{ fontWeight: "bold" }}> MADHU FILAMENT</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CafTerms;
