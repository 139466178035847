import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Button,
  DialogActions,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDialog } from "../../../../contexts/DialogContext";
import usePlan from "../../../../hooks/usePlan";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import usePlanGroup from "../../../../hooks/usePlanGroup";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import { useTranslation } from "react-i18next";
import BasicDetail from "./BasicDetail";
const initialValues = {
  planGroupName: "",
  description: "",
  isSharingAllowed: true,
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  planGroupName: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

const CopyPlanGroupModal = ({ planGroupId }) => {
  const { unSetDialog } = useDialog();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { addPlanGroup, isSubmitting, getPlanGroup, planGroup } = usePlanGroup();

  const fetchData = async () => {
    await getPlanGroup(planGroupId);
  };

  useEffect(() => {
    fetchData();
  }, [planGroupId]);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (Object.keys(planGroup).length != 0) {
      const initialValues = {
        planGroupName: planGroup.planGroupName,
        planGroupId: planGroup.planGroupId,
        description: planGroup.description,
        isSharingAllowed: planGroup.isSharingAllowed,
        status: planGroup.status,
      };
      reset(initialValues);
    }
  }, [planGroup]);

  const onSubmit = async (data) => {
    try {
      console.log(data, "data000090000");
      await addPlanGroup(data);
      dispatch(showSuccessSnackbar("Plan group copied successfully!"));
      reset();
      unSetDialog("copy_plan_group");
    } catch (err) {
      console.log(err, "errerrerr");
      dispatch(showErrorSnackbar(err.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BasicDetail validationFields={validationSchema.fields} />

          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Add
            </Button>

            <Button variant="text" onClick={() => unSetDialog("map_plan_modal")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default CopyPlanGroupModal;
