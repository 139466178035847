import React, { useEffect, useState, useRef } from "react";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import { useTheme, useMediaQuery } from "@mui/material";
import { Card, CardHeader, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useWindowDimensions from "../../../utils/windowDimensions";
import useAgreementTracker from "../../../hooks/useAgreementTracker";
import useAuth from "../../../hooks/useAuth";
import { formatDate, addDays } from "../../../utils/common";

function AED() {
  const theme = useTheme();

  // Media queries for responsiveness
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablet devices
  const isLaptopM = useMediaQuery(theme.breakpoints.between("md", "lg")); // Laptop Medium (1280px–1440px)
  const isLaptopL = useMediaQuery(theme.breakpoints.up("lg")); // Laptop Large (1440px and above)

  const { user } = useAuth();
  const { width: mainContentWidth } = useMainContentDimensions();

  const currentPayload = {
    filters: [
      {
        fieldName: "dateOfExpiry",
        fieldValue:
          formatDate(addDays(new Date(), 30)) +
          " 00:00:00," +
          formatDate(addDays(new Date(), 30)) +
          " 23:59:59",
        op: "btn",
        dataType: "string",
        condition: "and",
        isJoin: false,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const { height } = useWindowDimensions();
  const { getAllAgreementTracker, agreementTrackers, paging, isLoading } = useAgreementTracker();

  const { totalNumberOfRecords, hasNextPage, pageNumber, pageSize } = paging;

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    if (Object.keys(payload).length !== 0) {
      await getAllAgreementTracker(payload);
    }
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page === 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "tenant",
      headerName: "Franchise Name",
      valueGetter: (params) => params.tenantName,
      width: isTablet ? 150 : isLaptopM ? 200 : isLaptopL ? 220 : 180, // Adjust column width
    },
    {
      field: "dateOfExpiryStr",
      headerName: "Agreement Expiry Date",
      width: isTablet ? 140 : isLaptopM ? 180 : isLaptopL ? 200 : 160, // Adjust column width
    },
  ];

  const paginationMetaRef = useRef();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = React.useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  return (
    <Card
      sx={{
        width: isTablet
          ? "95%" // Tablet width
          : isLaptopM
            ? mainContentWidth - 400 // Medium laptop width
            : isLaptopL
              ? "95%" // Large laptop width to prevent overflow
              : "100%", // Default width
        margin: "0 auto", // Center the card
        overflow: "hidden", // Prevent overflow
      }}
    >
      <CardHeader title="Agreement Expiry Details" />
      <CardContent
        sx={{
          height: isTablet
            ? height - 300 // Tablet height
            : isLaptopM
              ? height - 250 // Medium laptop height
              : isLaptopL
                ? height - 200 // Large laptop height
                : height - 200, // Default height
          overflowY: "auto", // Enable vertical scrolling if content overflows
        }}
      >
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          columns={columns}
          rows={agreementTrackers}
          refreshPage={fetchData}
          getRowId={(row) => row["agreementTrackerId"]}
          rowCount={paging?.totalNumberOfRecords}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging?.pageNumber}
          pageSize={5}
          paginationMode="server"
          onPaginationModelChange={onPageChange}
          paginationMeta={paginationMeta}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
          sx={{
            "& .MuiDataGrid-root": {
              maxWidth: "100%", // Prevent table overflow
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default AED;
