import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import usePlanGroup from "../../../../hooks/usePlanGroup";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useRate from "../../../../hooks/useRate";
import { searchPayload } from "../../../../utils/common";
import CustomCheckBox from "../../../../components/CustomCheckBox";

const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const currentRatePayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AssignedPlan(props) {
  const searchPayloadValue = searchPayload();
  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();
  const {
    autoSearchLoading: rateAutoSearchLoading,
    autoSearchRate,
    rateAutoSearchResults,
  } = useRate();
  const {
    validationFields,
    Plan,
    planGroupPlanOptions,
    setPlanGroupPlanOptions,
    ratesMap,
    setRatesMap,
  } = props;
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    resetField,
  } = useFormContext();

  const handleCheckboxChange = (optionValue) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [optionValue]: !prevState[optionValue],
    }));
  };

  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [ratePayload, setRatePayload] = useState(currentRatePayload);

  const [rateOptions, setRateOptions] = useState([]);

  const tenant = watch("tenant");
  const plan = watch("plan");
  const serviceType = watch("userServiceType");

  console.log(serviceType, "serviceType");

  useEffect(() => {
    console.log(tenant, serviceType, "dssssssssssssss");
    if (Object.keys(tenant).length != 0) {
      console.log(tenant, serviceType, "dssssssssssssss");
      setPlanGroupPlanOptions([]);
      setPlanGroupPlanPayload({
        ...planGroupPlanPayload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    } else {
      setPlanGroupPlanOptions([]);
    }
  }, [tenant]);

  const isPlanMounted = useRef(false);

  useEffect(() => {
    console.log(plan, "dsssssssssssssshhhhhhhhhhhh");
    if (plan && Object.keys(plan).length != 0) {
      setRateOptions([]);
      setRatePayload({
        ...ratePayload,
        filters: [
          {
            fieldName: "planId",
            fieldValue: plan.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    }
  }, [plan]);

  const fetchPlanGroupPlans = async () => {
    if (Object.keys(planGroupPlanPayload).length != 0 && planGroupPlanPayload.filters.length != 0) {
      await autoSearchPlanGroupPlan(planGroupPlanPayload);
    }
  };

  // const fetchRates = async () => {
  //   if (Object.keys(ratePayload).length != 0 && ratePayload.filters.length != 0) {
  //     await autoSearchRate(ratePayload);
  //   }
  // };

  const loadRates = async (planId) => {
    const updatedPayload = {
      ...ratePayload,
      filters: [
        {
          fieldName: "planId",
          fieldValue: planId,
          op: "eq",
          dataType: "int",
          condition: "and",
          isJoin: true,
        },
      ],
    };
    setRatePayload(updatedPayload);
    const rates = await autoSearchRate(updatedPayload);

    setRatesMap((prev) => ({ ...prev, [planId]: rates?.data }));
  };

  useEffect(() => {
    planGroupPlanOptions.forEach((plan) => {
      if (!ratesMap[plan.value]) {
        loadRates(plan.value);
      }
    });
  }, [planGroupPlanOptions]);

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  // useEffect(() => {
  //   fetchRates();
  // }, [ratePayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  console.log(planGroupPlanOptions, "auuuttuu");

  console.log(planGroupPlanAutoSearchResults, "plannhhhh");

  const selectedPlan = watch("plan");
  const selectedRate = watch("rate");
  console.log(selectedPlan, "selectedPlan");

  useEffect(() => {
    if (selectedPlan) {
      setValue("assigned-plan-" + selectedPlan.value, true);
    } else {
      planGroupPlanOptions.forEach((planGroupPlanOption) => {
        setValue("assigned-plan-" + planGroupPlanOption.value, false);
      });
    }
  }, [selectedPlan, planGroupPlanOptions, setValue]);

  useEffect(() => {
    if (selectedRate) {
      setValue("assigned-rate-" + selectedRate.value, true);
    }
  }, [selectedRate]);

  return (
    <Grid container spacing={3} className="row" pt={8} sx={{ paddingLeft: "16px" }}>
      {planGroupPlanOptions.map((planGroupPlanOption) => (
        <Grid item md={12} key={planGroupPlanOption.value}>
          <CustomCheckBox
            defaultValue={true}
            label={planGroupPlanOption.label}
            name={`assigned-plan-${planGroupPlanOption.value}`}
            type="checkbox"
            fields={validationFields}
            my={2}
            fullWidth
            errors={errors}
            disabled={selectedPlan["value"] === planGroupPlanOption.value}
          />

          <Grid container spacing={3} className="row" sx={{ paddingLeft: "16px" }}>
            {ratesMap[planGroupPlanOption.value]?.map((rate) => (
              <Grid item md={4} key={rate.value}>
                <CustomCheckBox
                  defaultValue={true}
                  label={rate.label}
                  name={`assigned-rate-${rate.value}`}
                  type="checkbox"
                  fields={validationFields}
                  my={2}
                  fullWidth
                  errors={errors}
                  disabled={selectedRate["value"] === rate.value}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default AssignedPlan;
