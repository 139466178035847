import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import useUser from "../../../hooks/useUser";
import UserFields from "./components/UserFields";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import PlanDetails from "./components/PlanDetails";
import BranchAndOthers from "./components/BranchAndOthers";
import IpTvPlan from "./components/IpTvPlan";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";

import AddressDetail from "../../../components/AddressDetail";
import InstallationAddressDetail from "../../../components/InstallationAddressDetail";
import AssignedPlan from "./components/AssignedPlan";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const initialValues = {
  userName: "",
  mobileNo: "",
  emailId: "",
  fullName: "",
  gstNo: "",
  address: "",
  installationAddress: "",
  tenant: {},
  branch: {},
  plan: {},
  rate: {},
  serialNo: "",
  routerPassword: "",
  appPassword: "",
  userServiceType: "INTERNET",
  ipTvPlanId: "",
  ipTvPlan: {},
  status: "ACTIVE",
  sameAsPresentAddress: 0,
  connectionType: "HOME_USER",
  accountType: "NORMAL_USER",
};

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Tenant is required"),
  branch: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Branch is required"),
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Plan is required"),
  rate: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Rate is required"),
  userName: Yup.string()
    .required("Required")
    .matches(/^[a-zA-Z0-9_-]+$/, "Please enter valid username"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  emailId: Yup.string("Enter email id")
    .required("Email id is required")
    .email("Enter a valid email"),
  routerPassword: Yup.string().max(255).required("Router password is required"),
  confirmRouterPassword: Yup.string()
    .required("Router password is required")
    .when("routerPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: (schema) =>
        Yup.string().oneOf([Yup.ref("routerPassword")], "Both password need to be the same"),
    }),
  appPassword: Yup.string()
    .matches(passwordRules, {
      message:
        "Please create a stronger password, Password must contain min 8 char, " +
        " 1 upper case letter, 1 lower case letter, 1 numeric digit",
    })
    .required("Required"),
  confirmAppPassword: Yup.string()
    .required("App password is required")
    .when("appPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: (schema) =>
        Yup.string().oneOf([Yup.ref("appPassword")], "Both password need to be the same"),
    }),
  fullName: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  installationAddress: Yup.string().required("Required"),
  userServiceType: Yup.string().required("Required"),
  connectionType: Yup.string().required("Required"),
  accountType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  ipTvPlan: Yup.object()
    .nullable()
    .when("userServiceType", {
      is: (val) => (val && val == "COMBO" ? true : false),
      then: (schema) =>
        Yup.object()
          .shape({
            label: schema.required(),
            value: schema.required(),
          })
          .nullable()
          .required("IpTv Plan is required"),
    }),
  serialNo: Yup.string().when("userServiceType", {
    is: (val) => (val && val == "COMBO" ? true : false),
    then: (schema) => schema.required("Serial no is required"),
  }),
  mac: Yup.string()
    .matches(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, "Invalid MAC address format")
    .when("accountType", {
      is: (val) => (val && val == "MAC_USER" ? true : false),
      then: (schema) => schema.required("Mac is required"),
    }),
});

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function AddUser() {
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);
  const [ratesMap, setRatesMap] = useState({});
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const classes = useTabStyles();

  const { addUser, hasError, errorMessage, isSubmitting } = useUser();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = methods;

  useEffect(() => {
    console.log(user, "useruserusersss");
    if (user && user.businessType == "LOCAL_CABLE_OPERATOR") {
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
    }
  }, [user]);

  console.log(errors, "errorserrorserrors");

  const onSubmit = async (data) => {
    try {
      const rateDto = {
        rateId: data.rate.value,
      };
      const planDto = {
        planId: data.plan.value,
        planName: data.plan.label,
      };
      const tenantDto = {
        tenantId: data.tenant.value,
        tenantName: data.tenant.label,
      };
      if (data.tenant != null) {
        data["tenant"] = tenantDto;
      }
      if (data.ipTvPlan != null) {
        data["ipTvPlanId"] = data.ipTvPlan.value;
      }
      if (data.userServiceType === "INTERNET") {
        delete data["ipTvPlanId"];
        delete data["serialNo"];
        delete data["ipTvPlan"];
      }
      console.log(data, "datadatadata122bef");
      data["rate"] = rateDto;
      data["plan"] = planDto;
      data["branch"] = {
        branchId: data.branch.value,
      };
      data["userType"] = "REGULAR";
      const selectedPlans = planGroupPlanOptions.filter((plan) =>
        watch(`assigned-plan-${plan.value}`),
      );

      const selectedRates = {};
      selectedPlans.forEach((plan) => {
        const rates = ratesMap[plan.value]?.filter((rate) => watch(`assigned-rate-${rate.value}`));
        selectedRates[plan.value] = rates || [];
      });
      let radUserPlans = [];

      for (let key in selectedRates) {
        //console.log("key--------", selectedRates[key]);
        for (let rateIndex in selectedRates[key]) {
          radUserPlans.push({
            plan: {
              planId: key,
              planName: "",
            },
            rate: {
              rateId: selectedRates[key][rateIndex]["value"],
              rateName: selectedRates[key][rateIndex]["label"],
            },
          });
        }
      }
      console.log(data, "user data details");
      data["assignedPlans"] = radUserPlans;
      await addUser(data);
      dispatch(showSuccessSnackbar("User added successfully!"));
      // setStatus({ sent: true });
      reset();
    } catch (err) {
      // setStatus({ sent: false });
      // setErrorSubmit({ submit: err.message });
      //console.log(err, "1111111111ss111");
      let messageDetails = [];
      if (err.errors != undefined) {
        let errors = err.errors;
        for (let error in errors) {
          messageDetails.push(errors[error].field + " " + errors[error].message);
        }
        dispatch(showErrorSnackbar(messageDetails.join(",")));
      } else {
        dispatch(showErrorSnackbar(err.message));
      }
      //dispatch(showErrorSnackbar(err.message));
    }
  };

  const cancelBtn = () => {
    navigate("/user/userlist/list");
  };

  const presentAddressFields = watch(["address"]);

  const Plan = watch("plan");

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  console.log(tabValue, "talllah");

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>User</Typography>

        <Typography>Add User</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_user")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                User added successfully!
              </Alert>
            )}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {errors.submit}
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    classes={{ scroller: classes.scroller }}
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="User"
                    sx={{
                      justifyContent: "center",
                      width: {
                        xs: "100%", // Full width on mobile devices
                        sm: "500px", // Fixed width on small devices
                        md: "700px", // Wider on medium devices
                        lg: "900px", // Even wider on larger devices
                      },
                      minWidth: "300px", // Ensure a minimum width across all devices
                      maxWidth: "100%", // Ensure it doesn't overflow the container
                    }}
                  >
                    {" "}
                    <Tab label="User Details" {...a11yProps(0)} />
                    <Tab label="Address " {...a11yProps(1)} />
                    <Tab label="Plan" {...a11yProps(2)} />
                    <Tab label="Branch and Others" {...a11yProps(3)} />
                    <Tab label="Assigned Plan" {...a11yProps(4)} />
                    <Tab label="IpTv Plan" {...a11yProps(5)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <UserFields validationFields={validationSchema.fields} hideFields={[]} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <AddressDetail
                      presentAddressFields={presentAddressFields}
                      validationFields={validationSchema.fields}
                    />
                  </TabPanel>

                  <TabPanel value={tabValue} index={2}>
                    <PlanDetails validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <BranchAndOthers validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <AssignedPlan
                      validationFields={null}
                      Plan={Plan}
                      disabled={true}
                      planGroupPlanOptions={planGroupPlanOptions}
                      setPlanGroupPlanOptions={setPlanGroupPlanOptions}
                      ratesMap={ratesMap}
                      setRatesMap={setRatesMap}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <IpTvPlan validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddUser;
