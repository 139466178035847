import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProfileImg from "../../icons/profile_img.png";
import {
  Grid,
  IconButton,
  Divider as MuiDivider,
  Typography as MuiTypography,
  TableContainer,
} from "@mui/material";
import {
  PersonRefresh,
  Edit as EditIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Password as PasswordIcon,
  WifiPassword as WifiPasswordIcon,
  Call as CallIcon,
  MobileFriendly as MobileFriendlyIcon,
  Person as PersonIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  PendingActions as PendingActionsIcon,
  Payments as PaymentsIcon,
  CreditScore as CreditScoreIcon,
} from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import MailIcon from "@mui/icons-material/Mail";
import { color, fontFamily, fontWeight, spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";
// import Actions from "./Actions";
import useWindowDimensions from "../../../utils/windowDimensions";

import Stats from "./../../../components/Stats";
import useDashboard from "../../../hooks/useDashboard";
import useAuth from "../../../hooks/useAuth";
import useTenant from "../../../hooks/useTenant";
import useDailyUser from "../../../hooks/useDailyUser";
import {
  ButtonBase,
  Button,
  Tabs,
  CardHeader,
  CardMedia,
  Tab,
  Box,
  Paper,
  Avatar,
  Card,
  CardContent,
  CardActions,
  TableBody,
  TableHead,
  Table,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import useUnpaidInvoice from "../../../hooks/useUnpaidInvoice";
import CustomDataGrid from "./CustomDataGrid";
import DashboardTable from "../../dashboards/Default/Table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CafTerms from "./CafTermsandConditions";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingBottom: "0px", // Adjust this to control row spacing
          paddingTop: "4px",
          lineHeight: "1.2",
        },
      },
    },
  },
});

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

const font = createTheme({
  typography: {
    fontFamily: "Times New Roman",
  },
});

function CafForm() {
  const classes = useTabStyles();
  const { user } = useAuth();
  const { getTenant, tenant } = useTenant();

  const { getUnpaidInvoice, unpaidInvoices, unpaidInvoice, isLoading, paging } = useUnpaidInvoice();

  const { getAllDashboardUser, dashboardUsers } = useDailyUser();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const { getDashboardUserCount, dashboadUserCount, isUserDashboardLoading } = useDashboard();

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const fetchDashboardData = async () => {
    await getDashboardUserCount(payload);
  };

  const fetchTenantData = async () => {
    await getTenant(user.tenantId);
  };

  console.log(dashboardUsers, "onloiiinne");

  const [fullName, setFullName] = useState("");

  const [walletBalance, setWalletBalance] = useState(0.0);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user) {
      setFullName(user.name);
      fetchDashboardData();
    }
    if (user && user.businessType != "SUPER_ADMIN") {
      fetchTenantData();
    }
  }, [user]);

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    console.log(tenant, "tenanttenant");
    if (Object.keys(tenant).length !== 0) {
      setWalletBalance(tenant.walletBalance);
    }
  }, [tenant]);

  return (
    <>
      <Paper sx={{ backgroundColor: "#ffffff", padding: "16px", fontFamily: "Times New Roman" }}>
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item style={{ color: "blue" }}>
                <ButtonBase sx={{ width: 128, height: 128 }}>
                  <Img alt="complex" src={ProfileImg} />
                </ButtonBase>
                <Typography variant="h4">Blue Lotus Support Services Pvt Ltd</Typography>
                <Typography variant="h4">(Limras Eronet Broadband Services Pvt Ltd)</Typography>
                <Typography variant="h4">
                  No.156, Doshi Towers,1st Floor, Poonamalle High Road,
                </Typography>
                <Typography variant="h4">Kilpauk, Chennai - 600 010</Typography>
                <Typography variant="h4">Contact No: 044-40247247</Typography>
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  sx={{ height: "100%" }}
                >
                  <Grid item style={{ color: "blue" }}>
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                      <Img alt="complex" src={ProfileImg} />
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                CUSTOMER APPLICATION FORM
              </Typography>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="row" spacing={2}>
                <Grid item xs>
                  <Grid container direction="column" alignItems="left" sx={{ fontSize: "20px" }}>
                    <Grid item>
                      CAF No :<span style={{ fontWeight: "bold" }}> 2022/273114</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{ fontWeight: "bold", fontSize: "20px" }}
                  >
                    <Grid item>Internet Access</Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid container direction="column" alignItems="end" sx={{ fontSize: "20px" }}>
                    <Grid item>
                      Date <span style={{ fontWeight: "bold" }}>6 Oct 2023</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={2}>
              <Grid item xs>
                <Grid container direction="column" alignItems="left" sx={{ fontSize: "20px" }}>
                  <Grid item>
                    Subscriber Type{" "}
                    <Box
                      component="span"
                      sx={{
                        border: "1px solid #000",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      Home User
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 4 }}>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  {/* Row 1 */}
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "15%", // Set 20% width for column 1
                      }}
                    >
                      Name of Customer
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "85%", // Set 80% width for column 2
                        fontWeight: "bold",
                      }}
                    >
                      MADHU FILAMENT
                    </TableCell>
                  </TableRow>
                  {/* Row 2 */}
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "15%",
                      }}
                    >
                      Customer ProfileID
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "85%",
                        fontWeight: "bold",
                      }}
                    >
                      madhufilament1_krr
                    </TableCell>
                  </TableRow>
                  {/* Row 3 */}
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "15%",
                      }}
                    >
                      Billing Address
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "85%",
                        fontWeight: "bold",
                      }}
                    >
                      PLOT NO .92,KARUR ATHUR INDUSTRIAL ESTATE (SIDCO), Vennaimalai Post,Karur
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Row 4 */}
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "20%",
                      }}
                    >
                      City : <span style={{ fontWeight: "bold" }}>Karur</span>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "40%",
                        borderRight: "1px solid grey",
                      }}
                    >
                      State : <span style={{ fontWeight: "bold" }}>Tamil Nadu (TN)</span>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "40%",
                      }}
                    >
                      Mobile : <span style={{ fontWeight: "bold" }}>9787217711</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "15%",
                      }}
                    >
                      Telephone : --
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "85%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        EMAIL : manjunathan.p@madhufilament.com
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 4 }}>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Id Proof
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      ID no
                    </TableCell>
                  </TableRow>
                  {/* Row 2 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Customer ProfileID
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      madhufilament1_krr
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h5" sx={{ textDecoration: "underline", mt: 4 }}>
              Service Installation Address
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "10%",
                      }}
                    >
                      Address
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "90%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        226-A, M. G. Road, 1st cross, Vaiyapuri Nagar, PO:Sengunthapuram, Karur.
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* Row 2 */}
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "15%",
                      }}
                    >
                      City :<span style={{ fontWeight: "bold" }}>Karur</span>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "85%",
                      }}
                    >
                      State :<span style={{ fontWeight: "bold" }}>:Tamil Nadu (TN)</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h5" sx={{ textDecoration: "underline", mt: 4 }}>
              Service Requirements
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "10%",
                      }}
                    >
                      Plan Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "90%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Speed_200Mbps_UL_899 </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* Row 2 */}
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      IP's Required :
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      Static IP No :--
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* Row 3 */}
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "70%",
                      }}
                    >
                      OLT Brand
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "30%",
                      }}
                    >
                      SI.No
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      ONU Brand
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      ONU MAC ID
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Terminating Network Equipment</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "25%",
                      }}
                    >
                      Router No
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "25%",
                      }}
                    >
                      Switch{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "25%",
                      }}
                    >
                      Standalone PC{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "25%",
                      }}
                    >
                      ONU{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Customer Mile Details</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table size="small" sx={{ borderCollapse: "collapse" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "20%",
                      }}
                    >
                      Coper{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "40%",
                        borderRight: "1px solid grey",
                      }}
                    >
                      Fiber{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "40%",
                      }}
                    >
                      RF Tower{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "1px solid #ccc", borderRadius: "0px" }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: "1px solid grey",
                        padding: "8px",
                        width: "70%",
                      }}
                    >
                      Date :6 Oct 2023
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "8px",
                        width: "30%",
                      }}
                    >
                      Place
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer
              component={Paper}
              sx={{ width: "100%", borderBottom: "1px solid #ccc", mt: 6, borderRadius: "0px" }}
            >
              <Table size="small">
                <TableBody>
                  {/* Row 1 */}
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "8px",
                        width: "50%", // Set 20% width for column 1
                      }}
                    >
                      Name Authorized Signatory
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        padding: "8px",
                        width: "50%", // Set 80% width for column 2
                      }}
                    >
                      Signature of Customer
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Card>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Name Authorized Signatory
                    </TableCell>
                    <TableCell align="right">Signature of Customer</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card> */}
          </Grid>
          <Box
            sx={{
              border: "1px solid #000",
              padding: "45px",
              marginTop: "20px", // Adds top space
              width: "30%",
            }}
          >
            <i> Service Franchise</i>
          </Box>
          <Typography align="center" sx={{ fontWeight: "bold" }}>
            *** This is a Computer Generated CAF ***
          </Typography>
          <Typography align="center" variant="h4" sx={{ fontWeight: "bold", mt: "50px" }}>
            BLUE LOTUS SUPPORT SERVICES PVT LTD.
          </Typography>
          <Typography align="center" variant="h5" sx={{ textDecoration: "underline", mb: "20px" }}>
            TERMS AND CONDITION
          </Typography>
        </React.Fragment>
      </Paper>
      <React.Fragment>
        <Card mb={6}>
          <CafTerms />
        </Card>
      </React.Fragment>
    </>
  );
}

export default CafForm;
