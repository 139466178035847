import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Grid, Divider as MuiDivider, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";

import CustomDataGrid from "./CustomDataGrid";
import useReports from "../../../hooks/useReports";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Stats from "./../../../components/Stats";
import useAuth from "../../../hooks/useAuth";
import { roundOff } from "../../../utils/common";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function SharingReports() {
  const { user } = useAuth();
  const { getSharingReports, isLoading, sharingReports, downloadSharingReport } = useReports();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "desc",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getSharingReports(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadSharingReport(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };
  const showDistributor = () => {
    if (user && user.businessType != "LOCAL_CABLE_OPERATOR") {
      return (
        <>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="MSO Share Amt"
              amount={"₹" + roundOff(sharingReports.sharingReportSum.msoShareAmount)}
              chip="Annual"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stats
              title="Dist Share Amt"
              amount={"₹" + roundOff(sharingReports.sharingReportSum.distShareAmount)}
              chip="Weekly"
            />
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="MSO Share Amt"
            amount={
              "₹" +
              roundOff(
                sharingReports.sharingReportSum.msoShareAmount +
                  sharingReports.sharingReportSum.distShareAmount,
              )
            }
            chip="Annual"
          />
        </Grid>
      );
    }
  };

  // useEffect(() => {
  //   console.log(user, "useruseruser222");
  //   if (user && user.businessType != "LOCAL_CABLE_OPERATOR") {
  //   }
  // }, [user]);

  return (
    <React.Fragment>
      <Helmet title="SaaS Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Sharing Statistics
          </Typography>
        </Grid>
      </Grid>
      <Divider my={4} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Renewal Amount"
            amount={"₹" + roundOff(sharingReports.sharingReportSum.renewalAmount)}
            chip="Monthly"
          />
        </Grid>
        {showDistributor()}
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="LCO Share Amt"
            amount={"₹" + roundOff(sharingReports.sharingReportSum.lcoShareAmount)}
            chip="Weekly"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Tax Amt"
            amount={"₹" + roundOff(sharingReports.sharingReportSum.taxAmount)}
            chip="Weekly"
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={null}
            editPage={null}
            refreshPage={refreshPage}
            downloadFile={exportFile}
            openDownloadDialog={null}
            id="renewId"
            data={sharingReports.data}
            rowCount={sharingReports.paging.totalNumberOfRecords}
            paging={{
              page: sharingReports.paging.pageNumber,
              pageSize: sharingReports.paging.pageSize,
            }}
            onPaginationModelChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            setPayload={setPayload}
            payload={payload}
            setFilterResetFunc={setFilterResetFunc}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SharingReports;
