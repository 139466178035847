import { RadUserPlanConstants } from "../constants/raduser_plan.constants";

const AddonReducer = (state, action) => {
  switch (action.type) {
    case RadUserPlanConstants.FETCH_RADUSER_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserPlanConstants.FETCH_RADUSER_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        radUserPlans: action.payload.data,
        paging: action.payload.paging,
      };
    case RadUserPlanConstants.FETCH_RADUSER_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case RadUserPlanConstants.ADD_RADUSER_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserPlanConstants.ADD_RADUSER_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case RadUserPlanConstants.ADD_RADUSER_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case RadUserPlanConstants.RADUSER_PLAN_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case RadUserPlanConstants.RADUSER_PLAN_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        radUserPlanAutoSearchResults: action.payload,
      };
    case RadUserPlanConstants.RADUSER_PLAN_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    case RadUserPlanConstants.FETCH_RADUSER_RATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case RadUserPlanConstants.FETCH_RADUSER_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        radUserRates: action.payload,
      };
    case RadUserPlanConstants.FETCH_RADUSER_RATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default AddonReducer;
