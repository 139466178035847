import { createContext, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import UserReducer from "../reducers/UserReducer";
import { UserConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  users: [],
  onlineUsers: [],
  userMaterials: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  userSearchResults: [],
  user: {
    userName: "",
    userServiceType: "",
    status: "",
    emailId: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    rate: {
      rateFullName: "",
    },
    plan: {
      planName: "",
      totalLimit: "",
    },
  },
  isUploading: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  userAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  globalAutoSearchResults: [],
  autoSearchLoading: false,
  isLoading: false,
  isGetUserLoading: true,
  isDownload: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
  isRenewSubmitting: false,
  renewals: [],
  renewal: {},
  userMaterial: {},
  staticIpAddress: {},
  updateUserName: {},
  showPass: {},
  suspendUser: {},
  showAppPass: {},
  isUserDashboardLoading: true,
  dashboadUserCount: {
    totalCount: 0,
    onlineCount: 0,
    activeCount: 0,
    expireCount: 0,
    suspendedCount: 0,
  },
  userDetailsInvoice: {
    invoiceAmount: 0,
    dueAmount: 0,
    paidAmount: 0,
  },
  uploadMessage: "",
  userOrderId: "",
  nextExpirationDate: "",
};
const UserContext = createContext({
  ...initialState,
  getAllUser: () => {},
  addUser: () => {},
  renewUser: () => {},
  addUserMaterial: () => {},
  suspendUser: () => {},
  disconnectUser: () => {},
  sessionClose: () => {},
  closeUserSession: () => {},
  getAllUserMaterial: () => {},
  searchUser: () => {},
  autoSearchUser: () => {},
  updateOnHold: () => {},
  getUser: () => {},
  changeValidity: () => {},
  addAutoRenewal: () => {},
  getUserMaterial: () => {},
  getShowPassword: () => {},
  getShowAppPassword: () => {},
  updateUserMaterial: () => {},
  updateStaticIpAddress: () => {},
  updateUser: () => {},
  updateRouterPassword: () => {},
  updateAppPassword: () => {},
  updatePlan: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  getOnlineUser: () => {},
  getRenewal: () => {},
  getDashboardUserCount: () => {},
  getUserDetailsInvoice: () => {},
  globalAutoSearchUser: () => {},
  createOrder: () => {},
  cancelOrder: () => {},
  updateUserName: () => {},
  getNextExpirationDate: () => {},
  updatePaidToUnpaid: () => {},
});
function UserProvider({ children }) {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const getAllUser = async (payload) => {
    try {
      dispatch({
        type: UserConstants.FETCH_USER_REQUEST,
      });
      const response = await axios.post("/radius/raduser/list", payload);
      dispatch({
        type: UserConstants.FETCH_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.FETCH_USER_FAILURE,
      });
    }
  };
  const getAllUserMaterial = async (payload) => {
    try {
      dispatch({
        type: UserConstants.FETCH_MATERIAL_REQUEST,
      });
      const response = await axios.post("/radius/raduser/material/list", payload);
      dispatch({
        type: UserConstants.FETCH_MATERIAL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.FETCH_MATERIAL_FAILURE,
      });
    }
  };
  const getUserMaterial = async (id) => {
    const response = await axios.get("/radius/raduser/material/" + id);
    dispatch({
      type: UserConstants.GET_MATERIAL_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };
  const getShowPassword = async (radUserId) => {
    const response = await axios.get("/radius/raduser/show-router-password/" + radUserId);
    dispatch({
      type: UserConstants.GET_SHOW_ROUTER_PASSWORD_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_SHOW_ROUTER_PASSWORD_SUCCESS,
      payload: response.data,
    });
  };

  const getShowAppPassword = async (radUserId) => {
    const response = await axios.get("/radius/raduser/show-app-password/" + radUserId);
    dispatch({
      type: UserConstants.GET_SHOW_APP_PASSWORD_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_SHOW_APP_PASSWORD_SUCCESS,
      payload: response.data,
    });
  };

  const updateUserMaterial = async (payload) => {
    const response = await axios.put("/radius/raduser/material/" + payload.radUserId, payload);
    dispatch({
      type: UserConstants.UPDATE_MATERIAL_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };
  const disconnectUser = async (userId) => {
    const response = await axios.post("/radius/raduser/disconnect-user-session/" + userId);
    dispatch({
      type: UserConstants.ADD_DISCONNECT_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_DISCONNECT_USER_SUCCESS,
      payload: response.data,
    });
  };
  const sessionClose = async (userId) => {
    const response = await axios.post("/radius/raduser/close-user-session/" + userId);
    dispatch({
      type: UserConstants.ADD_SESSION_CLOSE_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_SESSION_CLOSE_SUCCESS,
      payload: response.data,
    });
  };
  const closeUserSession = async (userId) => {
    const response = await axios.post("/radius/raduser/close-user-session/" + userId);
    dispatch({
      type: UserConstants.ADD_CLOSE_USER_SESSION_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_CLOSE_USER_SESSION_SUCCESS,
      payload: response.data,
    });
  };
  const searchUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/search", payload);
      dispatch({
        type: UserConstants.USER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_SEARCH_FAILURE,
      });
    }
  };
  const getDashboardUserCount = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/dashboard-count", payload);
      dispatch({
        type: UserConstants.USER_DASHBOARD_COUNT_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_DASHBOARD_COUNT_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_DASHBOARD_COUNT_FAILURE,
      });
    }
  };
  const getUserDetailsInvoice = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/user-details-invoice", payload);
      dispatch({
        type: UserConstants.USER_DETAIL_INVOICE_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_DETAIL_INVOICE_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_DETAIL_INVOICE_FAILURE,
      });
    }
  };
  const autoSearchUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/auto-search", payload);
      dispatch({
        type: UserConstants.USER_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.USER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: UserConstants.USER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const globalAutoSearchUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/global-auto-search", payload);
      dispatch({
        type: UserConstants.GLOBAL_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: UserConstants.GLOBAL_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.GLOBAL_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const getOnlineUser = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/online", payload);
      dispatch({
        type: UserConstants.ONLINE_USER_REQUEST,
      });
      dispatch({
        type: UserConstants.ONLINE_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: UserConstants.ONLINE_USER_FAILURE,
      });
    }
  };
  const addUser = async (payload) => {
    const response = await axios.post("/radius/raduser/add", payload);
    dispatch({
      type: UserConstants.RENEW_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.RENEW_USER_SUCCESS,
      payload: response.data,
    });
  };

  const changeValidity = async (payload) => {
    const response = await axios.post("/radius/raduser/change-expiry", payload);
    dispatch({
      type: UserConstants.CHANGE_VALIDITY_REQUEST,
    });
    dispatch({
      type: UserConstants.CHANGE_VALIDITY_SUCCESS,
      payload: response.data,
    });
  };

  const createOrder = async (payload) => {
    const response = await axios.post("/payment/create-order", payload);
    dispatch({
      type: UserConstants.CREATE_ORDER_REQUEST,
    });
    dispatch({
      type: UserConstants.CREATE_ORDER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };
  const cancelOrder = async (payload) => {
    const response = await axios.post("/payment/cancel-order-user", payload);
    dispatch({
      type: UserConstants.CANCEL_ORDER_REQUEST,
    });
    dispatch({
      type: UserConstants.CANCEL_ORDER_SUCCESS,
      payload: response.data,
    });
  };
  const addUserMaterial = async (payload) => {
    const response = await axios.post("/radius/raduser/material/add", payload);
    dispatch({
      type: UserConstants.ADD_MATERIAL_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_MATERIAL_SUCCESS,
      payload: response.data,
    });
  };
  const renewUser = async (payload) => {
    const response = await axios.post("/radius/raduser/renew", payload);
    dispatch({
      type: UserConstants.ADD_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_USER_SUCCESS,
      payload: response.data,
    });
  };
  const getUser = async (id) => {
    dispatch({
      type: UserConstants.GET_USER_REQUEST,
    });
    const response = await axios.get("/radius/raduser/" + id);
    dispatch({
      type: UserConstants.GET_USER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };
  const getRenewal = async (id) => {
    const response = await axios.get("/radius/raduser/renewal/" + id);
    dispatch({
      type: UserConstants.GET_RENEWAL_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_RENEWAL_SUCCESS,
      payload: response.data,
    });
  };
  const updateUser = async (payload) => {
    const response = await axios.put("/radius/raduser/update/" + payload.radUserId, payload);
    dispatch({
      type: UserConstants.UPDATE_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const suspendUser = async (payload) => {
    const { radUserId, isSuspended } = payload; // Destructure to extract necessary fields
    const response = await axios.put(`/radius/raduser/suspend-user/${radUserId}/${isSuspended}`);

    dispatch({
      type: UserConstants.UPDATE_SUSPEND_USER_REQUEST,
    });

    dispatch({
      type: UserConstants.UPDATE_SUSPEND_USER_SUCCESS,
      payload: response.data,
    });
  };

  const updateStaticIpAddress = async (payload) => {
    const { userName, ipAddress } = payload; // Destructure to extract necessary fields
    const response = await axios.put(
      `/radius/raduser/update-static-ipaddress/${userName}/${ipAddress}`,
    );

    dispatch({
      type: UserConstants.UPDATE_STATIC_IP_ADDRESS_REQUEST,
    });

    dispatch({
      type: UserConstants.UPDATE_STATIC_IP_ADDRESS_SUCCESS,
      payload: response.data,
    });
  };

  const updateRouterPassword = async (payload) => {
    const response = await axios.put("/radius/raduser/change-router-password", payload);
    dispatch({
      type: UserConstants.UPDATE_PWD_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PWD_SUCCESS,
      payload: response.data,
    });
  };

  const updateOnHold = async (payload) => {
    const response = await axios.put("/radius/raduser/update-on-hold", payload);
    dispatch({
      type: UserConstants.UPDATE_ON_HOLD_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_ON_HOLD_SUCCESS,
      payload: response.data,
    });
  };

  const addAutoRenewal = async (payload) => {
    const response = await axios.post("/radius/raduser/enable-auto-renewal", payload);
    dispatch({
      type: UserConstants.ADD_AUT0_RENEWAL_REQUEST,
    });
    dispatch({
      type: UserConstants.ADD_AUT0_RENEWAL_SUCCESS,
      payload: response.data,
    });
  };
  const updateAppPassword = async (payload) => {
    const response = await axios.put("/radius/raduser/change-app-password", payload);
    dispatch({
      type: UserConstants.UPDATE_PWD_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PWD_SUCCESS,
      payload: response.data,
    });
  };
  const updatePlan = async (payload) => {
    const response = await axios.put("/radius/raduser/change-plan", payload);
    dispatch({
      type: UserConstants.UPDATE_PLAN_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const updateUserName = async (payload) => {
    const response = await axios.put("/radius/raduser/update-username", payload);
    dispatch({
      type: UserConstants.UPDATE_USER_NAME_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_USER_NAME_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: UserConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "user." + fileType);
    dispatch({
      type: UserConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };
  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/raduser/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_user." + fileType);
    dispatch({
      type: UserConstants.DOWNLOAD_USER_REQUEST,
    });
    dispatch({
      type: UserConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };
  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: UserConstants.UPLOAD_USER_REQUEST,
      });
      const response = await axios({
        url: "/radius/raduser/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: UserConstants.UPLOAD_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: UserConstants.UPLOAD_USER_FAILURE,
        payload: error.message,
      });
    }
  };

  const getNextExpirationDate = async (radUserId, rateId) => {
    const response = await axios.get(`/radius/raduser/next-expiration-date/${radUserId}/${rateId}`);
    dispatch({
      type: UserConstants.GET_NEXT_EXPIRATION_REQUEST,
    });
    dispatch({
      type: UserConstants.GET_NEXT_EXPIRATION_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };

  const updatePaidToUnpaid = async (renewId) => {
    const response = await axios.put(`/radius/raduser/paid-to-unpaid-invoice/${renewId}`);
    dispatch({
      type: UserConstants.UPDATE_PAID_TO_UNPAID_REQUEST,
    });
    dispatch({
      type: UserConstants.UPDATE_PAID_TO_UNPAID_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        getAllUser,
        addUser,
        renewUser,
        addUserMaterial,
        getAllUserMaterial,
        searchUser,
        autoSearchUser,
        changeValidity,
        getShowAppPassword,
        getUser,
        disconnectUser,
        addAutoRenewal,
        updateUser,
        updateRouterPassword,
        updateAppPassword,
        sessionClose,
        updatePlan,
        downloadFile,
        getUserMaterial,
        getShowPassword,
        updateUserMaterial,
        updateStaticIpAddress,
        suspendUser,
        sampleFile,
        uploadFile,
        getOnlineUser,
        getRenewal,
        getDashboardUserCount,
        getUserDetailsInvoice,
        closeUserSession,
        globalAutoSearchUser,
        createOrder,
        cancelOrder,
        updateUserName,
        getNextExpirationDate,
        updateOnHold,
        updatePaidToUnpaid,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export { UserContext, UserProvider };
