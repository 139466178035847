export const RadUserPlanConstants = {
  FETCH_RADUSER_PLAN_REQUEST: "FETCH_RADUSER_PLAN_REQUEST",
  FETCH_RADUSER_PLAN_SUCCESS: "FETCH_RADUSER_PLAN_SUCCESS",
  FETCH_RADUSER_PLAN_FAILURE: "FETCH_RADUSER_PLAN_FAILURE",
  ADD_RADUSER_PLAN_REQUEST: "ADD_RADUSER_PLAN_REQUEST",
  ADD_RADUSER_PLAN_SUCCESS: "ADD_RADUSER_PLAN_SUCCESS",
  ADD_RADUSER_PLAN_FAILURE: "ADD_RADUSER_PLAN_FAILURE",
  RADUSER_PLAN_AUTO_SEARCH_REQUEST: "RADUSER_PLAN_AUTO_SEARCH_REQUEST",
  RADUSER_PLAN_AUTO_SEARCH_SUCCESS: "RADUSER_PLAN_AUTO_SEARCH_SUCCESS",
  RADUSER_PLAN_AUTO_SEARCH_FAILURE: "RADUSER_PLAN_AUTO_SEARCH_FAILURE",
  FETCH_RADUSER_RATE_REQUEST: "FETCH_RADUSER_RATE_REQUEST",
  FETCH_RADUSER_RATE_SUCCESS: "FETCH_RADUSER_RATE_SUCCESS",
  FETCH_RADUSER_RATE_FAILURE: "FETCH_RADUSER_RATE_FAILURE",
};
