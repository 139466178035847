import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, DialogActions, Button, CircularProgress } from "@mui/material";
import { useFormContext, useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";

import usePlanGroup from "../../../../hooks/usePlanGroup";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useRate from "../../../../hooks/useRate";
import { searchPayload } from "../../../../utils/common";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import useUser from "../../../../hooks/useUser";
import useRadUserPlan from "../../../../hooks/useRadUserPlan";
import { useDialog } from "../../../../contexts/DialogContext";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const currentRatePayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AssignPlan(props) {
  const [ratesMap, setRatesMap] = useState({});
  const dispatch = useDispatch();
  const {
    autoSearchRadUserPlan,
    radUserPlanAutoSearchResults,
    addRadUserPlan,
    getAllRadUserRates,
  } = useRadUserPlan();
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [selectedRate, setSelectedRate] = useState(0);
  const { getUser, user } = useUser();
  const { autoSearchRate, rateAutoSearchResults } = useRate();
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);
  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();

  const { validationFields, userId } = props;
  const { t } = useTranslation();

  const methods = useForm({});

  const {
    reset,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    setValue,
    resetField,
  } = methods;

  const { unSetDialog, openDialog } = useDialog();

  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [ratePayload, setRatePayload] = useState(currentPlanGroupPlanPayload);
  const [radUserPlanPayload, setRadUserPlanPayload] = useState({
    filters: [
      {
        fieldName: "radUserId",
        fieldValue: userId,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  });

  useEffect(() => {
    if (user && Object.keys(user).length != 0) {
      if (user?.tenantId != undefined) {
        setPlanGroupPlanOptions([]);
        setPlanGroupPlanPayload({
          ...planGroupPlanPayload,
          filters: [
            {
              fieldName: "tenantId",
              fieldValue: user?.tenantId,
              op: "eq",
              dataType: "int",
              condition: "and",
              isJoin: true,
            },
          ],
        });
        setSelectedPlan(user.planId);
        setSelectedRate(user.rateId);
        setValue("assigned-plan-" + user.planId, true);
        setValue("assigned-rate-" + user.rateId, true);
      }
    } else {
      setPlanGroupPlanOptions([]);
    }
  }, [user]);

  const fetchPlanGroupPlans = async () => {
    if (Object.keys(planGroupPlanPayload).length != 0 && planGroupPlanPayload.filters.length != 0) {
      await autoSearchPlanGroupPlan(planGroupPlanPayload);
    }
  };

  const fetchRadUserPlans = async () => {
    await autoSearchRadUserPlan(radUserPlanPayload);
  };

  const loadRates = async (planId) => {
    const updatedPayload = {
      ...ratePayload,
      filters: [
        {
          fieldName: "planId",
          fieldValue: planId,
          op: "eq",
          dataType: "int",
          condition: "and",
          isJoin: true,
        },
      ],
    };
    setRatePayload(updatedPayload);
    const rates = await autoSearchRate(updatedPayload);

    setRatesMap((prev) => ({ ...prev, [planId]: rates?.data }));
  };

  const fetchUser = async () => {
    await getUser(userId);
  };

  const fetcRadUserRates = async (planId) => {
    const rates = await getAllRadUserRates(userId, planId);
    for (let index in rates) {
      setValue("assigned-rate-" + rates[index]["rateId"], true);
    }
  };

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  useEffect(() => {
    fetchUser(userId);
    fetchRadUserPlans();
  }, [userId]);

  useEffect(() => {
    planGroupPlanOptions.forEach((plan) => {
      if (!ratesMap[plan.value]) {
        loadRates(plan.value);
      }
    });
  }, [planGroupPlanOptions]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (radUserPlanAutoSearchResults.data.length !== 0) {
      for (let index in radUserPlanAutoSearchResults.data) {
        console.log(radUserPlanAutoSearchResults.data[index], "radUserPlanAutoSearchResults.data");
        setValue("assigned-plan-" + radUserPlanAutoSearchResults.data[index]["value"], true);
        fetcRadUserRates(radUserPlanAutoSearchResults.data[index]["value"]);
      }
    }
  }, [radUserPlanAutoSearchResults]);

  const onSubmit = async (data) => {
    try {
      let plans = [];
      const selectedPlans = planGroupPlanOptions.filter((plan) =>
        watch(`assigned-plan-${plan.value}`),
      );

      const selectedRates = {};
      selectedPlans.forEach((plan) => {
        const rates = ratesMap[plan.value]?.filter((rate) => watch(`assigned-rate-${rate.value}`));
        selectedRates[plan.value] = rates || [];
      });

      let radUserPlans = [];

      for (let key in selectedRates) {
        console.log("key--------", selectedRates[key]);
        for (let rateIndex in selectedRates[key]) {
          console.log("rateIndex--------", selectedRates[key]);
          radUserPlans.push({
            plan: {
              planId: key,
              planName: "",
            },
            rate: {
              rateId: selectedRates[key][rateIndex]["value"],
              rateName: selectedRates[key][rateIndex]["label"],
            },
          });
        }
      }

      if (radUserPlans.length == 0) {
        dispatch(showErrorSnackbar("Please select atleast one plan"));
      } else {
        let payload = {
          radUser: {
            radUserId: userId,
          },
          radUserPlans: radUserPlans,
        };
        await addRadUserPlan(payload);
        dispatch(showSuccessSnackbar("Plan Assigned successfully!"));
        unSetDialog("assign_plan");
      }
      // for (let key in data) {
      //   let splitPlan = key.split("-");
      //   if (data[key]) {
      //     plans.push({
      //       planId: splitPlan[2],
      //       planName: "",
      //     });
      //   }
      // }
      // if (plans.length == 0) {
      //   dispatch(showErrorSnackbar("Please select atleast one plan"));
      // } else {
      //   let payload = {
      //     radUser: {
      //       radUserId: userId,
      //     },
      //     plans: plans,
      //   };
      //   await addRadUserPlan(payload);
      //   dispatch(showSuccessSnackbar("Plan Assigned successfully!"));
      //   unSetDialog("assign_plan");
      // }
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} className="row" pt={8} sx={{ paddingLeft: "16px" }}>
            {planGroupPlanOptions.map((planGroupPlanOption) => (
              <Grid item md={12} key={planGroupPlanOption.value}>
                <CustomCheckBox
                  defaultValue={true}
                  label={planGroupPlanOption.label}
                  name={`assigned-plan-${planGroupPlanOption.value}`}
                  type="checkbox"
                  fields={validationFields}
                  my={2}
                  fullWidth
                  errors={errors}
                  disabled={selectedPlan === planGroupPlanOption.value}
                />

                <Grid container spacing={3} className="row" sx={{ paddingLeft: "16px" }}>
                  {ratesMap[planGroupPlanOption.value]?.map((rate) => (
                    <Grid item md={4} key={rate.value}>
                      <CustomCheckBox
                        defaultValue={true}
                        label={rate.label}
                        name={`assigned-rate-${rate.value}`}
                        type="checkbox"
                        fields={validationFields}
                        my={2}
                        fullWidth
                        errors={errors}
                        disabled={selectedRate === rate.value}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={false ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Assign
            </Button>

            <Button variant="text" onClick={() => unSetDialog("assign_plan")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AssignPlan;
