export const OtpConstants = {
  FETCH_OTP_REQUEST: "FETCH_OTP_REQUEST",
  FETCH_OTP_SUCCESS: "FETCH_OTP_SUCCESS",
  FETCH_OTP_FAILURE: "FETCH_OTP_FAILURE",
  ADD_OTP_REQUEST: "ADD_OTP_REQUEST",
  ADD_OTP_SUCCESS: "ADD_OTP_SUCCESS",
  ADD_OTP_FAILURE: "ADD_OTP_FAILURE",
  OTP_SEARCH_REQUEST: "OTP_SEARCH_REQUEST",
  OTP_SEARCH_SUCCESS: "OTP_SEARCH_SUCCESS",
  OTP_SEARCH_FAILURE: "OTP_SEARCH_FAILURE",
  OTP_AUTO_SEARCH_REQUEST: "OTP_AUTO_SEARCH_REQUEST",
  OTP_AUTO_SEARCH_SUCCESS: "OTP_AUTO_SEARCH_SUCCESS",
  OTP_AUTO_SEARCH_FAILURE: "OTP_AUTO_SEARCH_FAILURE",
  GET_OTP_REQUEST: "GET_OTP_REQUEST",
  GET_OTP_SUCCESS: "GET_OTP_SUCCESS",
  GET_OTP_FAILURE: "GET_OTP_FAILURE",
  UPDATE_OTP_REQUEST: "UPDATE_OTP_REQUEST",
  UPDATE_OTP_SUCCESS: "UPDATE_OTP_SUCCESS",
  UPDATE_OTP_FAILURE: "UPDATE_OTP_FAILURE",
  DOWNLOAD_OTP_REQUEST: "DOWNLOAD_OTP_REQUEST",
  DOWNLOAD_OTP_SUCCESS: "DOWNLOAD_OTP_SUCCESS",
  DOWNLOAD_OTP_FAILURE: "DOWNLOAD_OTP_FAILURE",
  UPLOAD_OTP_REQUEST: "UPLOAD_OTP_REQUEST",
  UPLOAD_OTP_SUCCESS: "UPLOAD_OTP_SUCCESS",
  UPLOAD_OTP_FAILURE: "UPLOAD_OTP_FAILURE",
};
