import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import CardSystemReducer from "../reducers/CardSystemReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { CardSystemConstants } from "../constants/cardsystem.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  cardSystems: [],
  prepaidCards: [],
  onlineCards: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  cardSystemSearchResults: [],
  cardSeriesCount: 0,
  prepaidCardCount: 0,
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  cardSystemAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const CardSystemContext = createContext({
  ...initialState,
  getAllCardSystem: () => {},
  getAllPrepaidCards: () => {},
  getPrepaidCardCount: () => {},
  getCardSeriesCount: () => {},
  addCardSystem: () => {},
  searchCardSystem: () => {},
  autoSearchCardSystem: () => {},
  getCardSystem: () => {},
  updateCardSystem: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  getAllOnlineCards: () => {},
});

function CardSystemProvider({ children }) {
  const [state, dispatch] = useReducer(CardSystemReducer, initialState);

  const getAllCardSystem = async (payload) => {
    try {
      dispatch({
        type: CardSystemConstants.FETCH_CARD_SYSTEM_REQUEST,
      });
      const response = await axios.post("/card-series/list", payload);
      dispatch({
        type: CardSystemConstants.FETCH_CARD_SYSTEM_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: CardSystemConstants.FETCH_CARD_SYSTEM_FAILURE,
      });
    }
  };
  const getAllPrepaidCards = async (payload) => {
    try {
      dispatch({
        type: CardSystemConstants.FETCH_PREPAID_CARD_REQUEST,
      });
      const response = await axios.post("/card-series/prepaid-cards", payload);
      dispatch({
        type: CardSystemConstants.FETCH_PREPAID_CARD_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: CardSystemConstants.FETCH_PREPAID_CARD_FAILURE,
      });
    }
  };

  const searchCardSystem = async (payload) => {
    try {
      const response = await axios.post("/card-series/search", payload);
      dispatch({
        type: CardSystemConstants.CARD_SYSTEM_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: CardSystemConstants.CARD_SYSTEM_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CardSystemConstants.CARD_SYSTEM_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchCardSystem = async (payload) => {
    try {
      const response = await axios.post("/card-series/auto-search", payload);
      dispatch({
        type: CardSystemConstants.CARD_SYSTEM_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: CardSystemConstants.CARD_SYSTEM_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: CardSystemConstants.CARD_SYSTEM_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addCardSystem = async (payload) => {
    const response = await axios.post("/card-series/add", payload);
    dispatch({
      type: CardSystemConstants.ADD_CARD_SYSTEM_REQUEST,
    });
    console.log(response.data, "addaddadd");
    dispatch({
      type: CardSystemConstants.ADD_CARD_SYSTEM_SUCCESS,
      payload: response.data,
    });
  };

  const getCardSystem = async (id) => {
    const response = await axios.get("/card-series/" + id);
    dispatch({
      type: CardSystemConstants.GET_CARD_SYSTEM_REQUEST,
    });
    dispatch({
      type: CardSystemConstants.GET_CARD_SYSTEM_SUCCESS,
      payload: response.data,
    });
  };
  const getPrepaidCardCount = async (id) => {
    const response = await axios.get("/card-series/prepaid-card/count");
    dispatch({
      type: CardSystemConstants.GET_PREPAID_CARD_COUNT_REQUEST,
    });
    dispatch({
      type: CardSystemConstants.GET_PREPAID_CARD_COUNT_SUCCESS,
      payload: response.data,
    });
  };
  const getCardSeriesCount = async (id) => {
    const response = await axios.get("/card-series/count");
    dispatch({
      type: CardSystemConstants.GET_CARD_SERIES_COUNT_REQUEST,
    });
    dispatch({
      type: CardSystemConstants.GET_CARD_SERIES_COUNT_SUCCESS,
      payload: response.data,
    });
  };

  const updateCardSystem = async (payload) => {
    const response = await axios.put("/card-series/update/" + payload.cardSystemId, payload);
    dispatch({
      type: CardSystemConstants.UPDATE_CARD_SYSTEM_REQUEST,
    });
    dispatch({
      type: CardSystemConstants.UPDATE_CARD_SYSTEM_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: CardSystemConstants.DOWNLOAD_CARD_SYSTEM_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/card-series/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "cardSystem." + fileType);
    dispatch({
      type: CardSystemConstants.DOWNLOAD_CARD_SYSTEM_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/card-series/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_cardSystem." + fileType);
    dispatch({
      type: CardSystemConstants.DOWNLOAD_CARD_SYSTEM_REQUEST,
    });
    dispatch({
      type: CardSystemConstants.DOWNLOAD_CARD_SYSTEM_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: CardSystemConstants.UPLOAD_CARD_SYSTEM_REQUEST,
      });
      const response = await axios({
        url: "/card-series/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: CardSystemConstants.UPLOAD_CARD_SYSTEM_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: CardSystemConstants.UPLOAD_CARD_SYSTEM_FAILURE,
      });
    }
  };
  const getAllOnlineCards = async (payload) => {
    try {
      dispatch({
        type: CardSystemConstants.ONLINE_CARD_REQUEST,
      });
      const response = await axios.post("/radius/raduser/online", payload);
      dispatch({
        type: CardSystemConstants.ONLINE_CARD_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: CardSystemConstants.ONLINE_CARD_FAILURE,
      });
    }
  };

  return (
    <CardSystemContext.Provider
      value={{
        ...state,
        getAllCardSystem,
        getAllPrepaidCards,
        getPrepaidCardCount,
        getCardSeriesCount,
        addCardSystem,
        searchCardSystem,
        autoSearchCardSystem,
        getCardSystem,
        updateCardSystem,
        downloadFile,
        sampleFile,
        uploadFile,
        getAllOnlineCards,
      }}
    >
      {children}
    </CardSystemContext.Provider>
  );
}

export { CardSystemContext, CardSystemProvider };
