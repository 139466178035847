//Masters

import RenewalReports from "../pages/reports/renewal_reports";
import DueReports from "../pages/reports/due_reports";
import CollectionReports from "../pages/reports/collection_reports";
import WalletReports from "../pages/reports/wallet_reports";
import SharingReports from "../pages/reports/sharing_reports";
import GstInvoice from "../pages/reports/gst_invoices/index.js";
import MaterialList from "../pages/reports/material_reports/index.js";
import HotelActivationList from "../pages/reports/hotel_activation_report/index.js";
//Composers
import PaidInvoiceComposer from "../composers/PaidInvoiceComposer.js";
import { ReportComposer } from "../composers/ReportComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import MaterialComposer from "../composers/MaterialComposer.js";
import HotelManagementComposer from "../composers/HotelManagementComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import { BalanceStatementComposer } from "../composers/BalanceStatementComposefr.js";
import BalanceStatement from "../pages/reports/balance_statement/index.js";
import ProtectedLayout from "../layouts/ProtectedLayout";
import PaymentGatewayReport from "../pages/reports/payment_gateway_report/index.js";
import DepositPaymentGatewayReport from "../pages/reports/deposit_payment_gateway_report/index.js";
import { PaymentProcessComposer } from "../composers/PaymentProcessComposer.js";
import { OtpComposer } from "../composers/OtpComposer.js";
import OtpReport from "../pages/reports/otp_reports/index.js";

const ReportRoutes = {
  path: "reports",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "renewal",
      element: (
        <ProtectedLayout>
          <ReportComposer>
            <RenewalReports />
          </ReportComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "dues",
      element: (
        <ProtectedLayout>
          <ReportComposer>
            <DueReports />
          </ReportComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "collection",
      element: (
        <ProtectedLayout>
          <ReportComposer>
            <CollectionReports />
          </ReportComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "wallet",
      element: (
        <ProtectedLayout>
          <ReportComposer>
            <WalletReports />
          </ReportComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "revenueshare",
      element: (
        <ProtectedLayout>
          <ReportComposer>
            <SharingReports />
          </ReportComposer>
        </ProtectedLayout>
      ),
    },
    {
      children: [
        {
          path: "gst-invoices",
          element: (
            <ProtectedLayout>
              <PaidInvoiceComposer>
                <GstInvoice />
              </PaidInvoiceComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "balance-statement",
          element: (
            <ProtectedLayout>
              <BalanceStatementComposer>
                <BalanceStatement />
              </BalanceStatementComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "material-reports",
          element: (
            <ProtectedLayout>
              <MaterialComposer>
                <MaterialList />
              </MaterialComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "hotel-activation-reports",
          element: (
            <ProtectedLayout>
              <HotelManagementComposer>
                <HotelActivationList />
              </HotelManagementComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "payment-gateway-reports",
          element: (
            <ProtectedLayout>
              <PaymentProcessComposer>
                <PaymentGatewayReport />
              </PaymentProcessComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "deposit-payment-gateway-reports",
          element: (
            <ProtectedLayout>
              <PaymentProcessComposer>
                <DepositPaymentGatewayReport />
              </PaymentProcessComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "otp-reports",
          element: (
            <ProtectedLayout>
              <OtpComposer>
                <OtpReport />
              </OtpComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default ReportRoutes;
