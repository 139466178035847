import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProfileImg from "../../icons/profile_img.png";
import {
  Grid,
  IconButton,
  Divider as MuiDivider,
  Typography as MuiTypography,
  TableContainer,
} from "@mui/material";

import useWindowDimensions from "../../../utils/windowDimensions";

import useDashboard from "../../../hooks/useDashboard";
import useAuth from "../../../hooks/useAuth";
import useTenant from "../../../hooks/useTenant";
import useDailyUser from "../../../hooks/useDailyUser";
import {
  ButtonBase,
  Button,
  Tabs,
  CardHeader,
  CardMedia,
  Tab,
  Box,
  Paper,
  Avatar,
  Card,
  CardContent,
  CardActions,
  TableBody,
  TableHead,
  Table,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import useUnpaidInvoice from "../../../hooks/useUnpaidInvoice";
import CustomDataGrid from "./CustomDataGrid";
import DashboardTable from "../../dashboards/Default/Table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingBottom: "0px", // Adjust this to control row spacing
          paddingTop: "4px",
          lineHeight: "1.2",
        },
      },
    },
  },
});

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

const font = createTheme({
  typography: {
    fontFamily: "Times New Roman",
  },
});

function ReceiptForm() {
  const classes = useTabStyles();
  const { user } = useAuth();
  const { getTenant, tenant } = useTenant();

  const { getUnpaidInvoice, unpaidInvoices, unpaidInvoice, isLoading, paging } = useUnpaidInvoice();

  const { getAllDashboardUser, dashboardUsers } = useDailyUser();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const { getDashboardUserCount, dashboadUserCount, isUserDashboardLoading } = useDashboard();

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const handlePrint = () => {
    window.print();
  };

  const handleCancel = () => {
    // Replace with your desired cancel functionality
    console.log("Cancel button clicked");
  };

  const fetchDashboardData = async () => {
    await getDashboardUserCount(payload);
  };

  const fetchTenantData = async () => {
    await getTenant(user.tenantId);
  };

  console.log(dashboardUsers, "onloiiinne");

  const [fullName, setFullName] = useState("");

  const [walletBalance, setWalletBalance] = useState(0.0);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (user) {
      setFullName(user.name);
      fetchDashboardData();
    }
    if (user && user.businessType != "SUPER_ADMIN") {
      fetchTenantData();
    }
  }, [user]);

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    console.log(tenant, "tenanttenant");
    if (Object.keys(tenant).length !== 0) {
      setWalletBalance(tenant.walletBalance);
    }
  }, [tenant]);

  return (
    <>
      <Paper sx={{ backgroundColor: "#ffffff", padding: "16px", fontFamily: "Times New Roman" }}>
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item style={{ color: "blue" }}>
                <ButtonBase sx={{ width: 128, height: 128 }}>
                  <Img alt="complex" src={ProfileImg} />
                </ButtonBase>
                <Typography variant="h4">Blue Lotus Support Services Pvt Ltd</Typography>
                <Typography variant="h4">(Limras Eronet Broadband Services Pvt Ltd)</Typography>
                <Typography variant="h4">
                  No.156, Doshi Towers,1st Floor, Poonamalle High Road,
                </Typography>
                <Typography variant="h4">Kilpauk, Chennai - 600 010</Typography>
                <Typography variant="h4">Contact No: 044-40247247</Typography>
              </Grid>
            </Grid>
            <TableContainer
              component={Paper}
              sx={{ width: "100%", borderBottom: "1px solid #ccc", mt: 6, borderRadius: "0px" }}
            >
              <Table size="small">
                <TableBody>
                  {/* Row 1 */}
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "1px solid #ccc",
                        borderLeft: "none",
                        borderRight: "none",
                        padding: "8px",
                        width: "70%", // Set 20% width for column 1
                      }}
                    >
                      Receipt No: <span>BLSS2024R4538462</span>
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        border: "1px solid #ccc",
                        borderLeft: "none",
                        borderRight: "none",
                        padding: "8px",
                        width: "20%", // Set 80% width for column 2
                      }}
                    >
                      Receipt Date: <span>11 Oct 2024</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              container
              sx={{
                display: "flex",
              }}
            >
              <Typography sx={{ paddingLeft: "25px" }}>
                RECEIVED WITH THANKS FROM MR./MRS{" "}
                <span style={{ fontWeight: "bold" }}> JJDNADMIN</span>
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography>
                SUM OF RUPEES <span style={{ fontWeight: "bold" }}> 774.08</span> TOWARDS
                SUBSCRIBTION/RENEWAL OF LIMRAS ERONET BROADBAND FOR THE
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography>
                SERVICE <span style={{ fontWeight: "bold" }}>BB_100MB_1500GB_656</span> PLAN{" "}
                <span style={{ fontWeight: "bold" }}>30 DAYS</span> AND SUBSCRIBED BY{" "}
                <span style={{ fontWeight: "bold" }}>JAGANNATHAN</span>
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography sx={{ paddingTop: "5px" }}>
                HEREBY, I CONFIRM THE INVOICE AMOUNT RECEIVED IS
                <span style={{ fontWeight: "bold" }}> 774.08</span> AND THE BALANCE IS
                <span style={{ fontWeight: "bold" }}> 0 </span>
              </Typography>
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ width: "100%", mt: 6, borderRadius: "0px" }}>
            <Table size="small">
              <TableBody>
                {/* Row 1 */}
                <TableRow>
                  <TableCell
                    align="right"
                    sx={{
                      width: "100%",
                      borderTop: "1px solid #ccc",
                      paddingBottom: "30px",
                    }}
                  >
                    AUTHORIZED SIGNATORY
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography align="center" sx={{ fontWeight: "bold", paddingBottom: "30px" }}>
            *** THIS IS A COMPUTER GENERATED RECEIPT ***
          </Typography>
          <Divider />
          <Grid container justifyContent="right" spacing={2} sx={{ mt: 3, padding: "16px" }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                sx={{ fontWeight: "bold" }}
              >
                Print
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                sx={{ fontWeight: "bold" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>
    </>
  );
}

export default ReceiptForm;
