import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import RadUserPlanReducer from "../reducers/RadUserPlanReducer";
import { RadUserPlanConstants } from "../constants/index";

const initialState = {
  isLoading: true,
  addOns: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  addonSearchResults: [],
  addon: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isLoadingDownload: false,
  radUserPlanAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  radUserRates: [],
  autoSearchLoading: false,
};

const RadUserPlanContext = createContext({
  ...initialState,
  getAllRadUserPlan: () => {},
  addRadUserPlan: () => {},
  autoSearchRadUserPlan: () => {},
  getAllRadUserRates: () => {},
});

function RadUserPlanProvider({ children }) {
  const [state, dispatch] = useReducer(RadUserPlanReducer, initialState);

  const getAllRadUserPlan = async (payload) => {
    try {
      dispatch({
        type: RadUserPlanConstants.FETCH_RADUSER_PLAN_REQUEST,
      });
      const response = await axios.post("/raduser-plan/list", payload);

      dispatch({
        type: RadUserPlanConstants.FETCH_RADUSER_PLAN_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: RadUserPlanConstants.FETCH_RADUSER_PLAN_FAILURE,
      });
    }
  };

  const getAllRadUserRates = async (radUserId, planId) => {
    try {
      dispatch({
        type: RadUserPlanConstants.FETCH_RADUSER_RATE_REQUEST,
      });
      const response = await axios.get(`/raduser-plan/rates/${radUserId}/${planId}`);

      dispatch({
        type: RadUserPlanConstants.FETCH_RADUSER_RATE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: RadUserPlanConstants.FETCH_RADUSER_RATE_FAILURE,
      });
    }
  };

  const autoSearchRadUserPlan = async (payload) => {
    try {
      const response = await axios.post("/raduser-plan/auto-search", payload);
      dispatch({
        type: RadUserPlanConstants.RADUSER_PLAN_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: RadUserPlanConstants.RADUSER_PLAN_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: RadUserPlanConstants.RADUSER_PLAN_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addRadUserPlan = async (payload) => {
    const response = await axios.post("/raduser-plan/add", payload);
    dispatch({
      type: RadUserPlanConstants.ADD_RADUSER_PLAN_REQUEST,
    });
    dispatch({
      type: RadUserPlanConstants.ADD_RADUSER_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <RadUserPlanContext.Provider
      value={{
        ...state,
        getAllRadUserPlan,
        addRadUserPlan,
        autoSearchRadUserPlan,
        getAllRadUserRates,
      }}
    >
      {children}
    </RadUserPlanContext.Provider>
  );
}

export { RadUserPlanContext, RadUserPlanProvider };
