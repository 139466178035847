import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import RateReducer from "../reducers/RateReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { RateConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  rates: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  rateSearchResults: [],
  isLoading: true,
  rateAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  rate: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  autoSearchLoading: false,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const RateContext = createContext({
  ...initialState,
  getAllRate: () => {},
  addRate: () => {},
  searchRate: () => {},
  autoSearchRate: () => {},
  getRate: () => {},
  updateRate: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function RateProvider({ children }) {
  const [state, dispatch] = useReducer(RateReducer, initialState);

  const getAllRate = async (payload) => {
    try {
      const response = await axios.post("/radius/rate/list", payload);
      dispatch({
        type: RateConstants.FETCH_RATE_REQUEST,
      });
      dispatch({
        type: RateConstants.FETCH_RATE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: RateConstants.FETCH_RATE_FAILURE,
      });
    }
  };
  const searchRate = async () => {
    try {
      const response = await axios.post("/radius/rate/search", initPayload);
      dispatch({
        type: RateConstants.RATE_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: RateConstants.RATE_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: RateConstants.RATE_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchRate = async (payload) => {
    try {
      const response = await axios.post("/radius/rate/auto-search", payload);
      dispatch({
        type: RateConstants.RATE_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: RateConstants.RATE_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: RateConstants.RATE_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addRate = async (payload) => {
    const response = await axios.post("/radius/rate/add", payload);
    dispatch({
      type: RateConstants.ADD_RATE_REQUEST,
    });
    dispatch({
      type: RateConstants.ADD_RATE_SUCCESS,
      payload: response.data,
    });
  };

  const getRate = async (id) => {
    const response = await axios.get("/radius/rate/" + id);
    dispatch({
      type: RateConstants.GET_RATE_REQUEST,
    });
    dispatch({
      type: RateConstants.GET_RATE_SUCCESS,
      payload: response.data,
    });
  };

  const updateRate = async (payload) => {
    const response = await axios.put("/radius/rate/update/" + payload.rateId, payload);
    dispatch({
      type: RateConstants.UPDATE_RATE_REQUEST,
    });
    dispatch({
      type: RateConstants.UPDATE_RATE_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    const response = await axios.post("/radius/rate/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "rate." + fileType);
    dispatch({
      type: RateConstants.DOWNLOAD_RATE_REQUEST,
    });
    dispatch({
      type: RateConstants.DOWNLOAD_RATE_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/rate/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_rate." + fileType);
    dispatch({
      type: RateConstants.DOWNLOAD_RATE_REQUEST,
    });
    dispatch({
      type: RateConstants.DOWNLOAD_RATE_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: RateConstants.UPLOAD_RATE_REQUEST,
      });
      const response = await axios({
        url: "/radius/rate/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: RateConstants.UPLOAD_RATE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: RateConstants.UPLOAD_RATE_FAILURE,
      });
    }
  };

  return (
    <RateContext.Provider
      value={{
        ...state,
        getAllRate,
        addRate,
        searchRate,
        autoSearchRate,
        getRate,
        updateRate,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </RateContext.Provider>
  );
}

export { RateContext, RateProvider };
