import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";

import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import CustomTextField from "../../../../components/CustomTextField";

import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  appPassword: Yup.string().max(255).required("App password is required"),
  confirmAppPassword: Yup.string()
    .required("App password is required")
    .when("appPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: () => Yup.string().oneOf([Yup.ref("appPassword")], "Both password need to be the same"),
    }),
});

const ChangeAppPassword = (props) => {
  const dispatch = useDispatch();
  const { isRenewSubmitting, updateAppPassword } = useUser();
  const { userName } = props;

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    try {
      const payload = {
        userName: userName,
        password: data.appPassword,
      };
      await updateAppPassword(payload);
      dispatch(showSuccessSnackbar("Application password changed successfully!"));
      unSetDialog("change_app_password");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomTextField
                label={t("password")}
                name="appPassword"
                type="password"
                my={2}
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label={t("confirm_password")}
                name="confirmAppPassword"
                type="password"
                my={2}
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Save
            </Button>

            <Button
              variant="text"
              onClick={() => unSetDialog("change_app_password")}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default ChangeAppPassword;
