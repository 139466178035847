import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

import SaveCancel from "../../../components/SaveCancel";
import useTenant from "../../../hooks/useTenant";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import TenantDetail from "./components/TenantDetail";
import AllowedIppool from "./components/AllowedIppool";
import AllowedPolicy from "./components/AllowedPlan";
import TenantAddressDetail from "../../../components/TenantAddressDetail";
import AllowedIpTvPlan from "./components/AllowedIpTvPlan";
import LoginDetail from "./components/LoginDetail";
import ContactDetail from "./components/ContactDetail";
import IpTvSettings from "./components/IpTvSettings";
import { useFormState, useFormDispatch } from "../../../contexts/TenantFormContext";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const tabFields = {
  0: [
    "businessType",
    "mso",
    "distributor",
    "tenantName",
    "officialMobileNo",
    "officialEmailId",
    "sharingType",
    "planGroup",
    "status",
  ],
  1: [
    "doorNo",
    "streetName",
    "pinCode",
    "villageName",
    "townName",
    "talukId",
    "districtId",
    "stateId",
    "countryId",
  ],
  2: ["userName", "password", "confirmPassword"],
  3: ["firstName", "lastName", "emailId", "mobileNo"],
  4: [],
};

const validationSchema = Yup.object().shape(
  {
    tenantName: Yup.string().required("Required"),
    emailId: Yup.string("Enter email id")
      .required("Email id is required")
      .email("Enter a valid email"),
    officialEmailId: Yup.string("Enter email id")
      .required("Email id is required")
      .email("Enter a valid email"),

    doorNo: Yup.string().required("Required"),
    streetName: Yup.string().required("Required"),
    mobileNo: Yup.string()
      .required("Required")
      .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
    officialMobileNo: Yup.string()
      .required("Required")
      .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    businessType: Yup.string().required("Required"),
    pinCode: Yup.string().required("Required"),
    talukId: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    townName: Yup.string().required("Required"),
    districtId: Yup.string().required("Required"),
    stateId: Yup.string().required("Required"),
    countryId: Yup.string().required("Required"),
    villageName: Yup.string().required("Required"),

    sharingType: Yup.string().required("Required"),
    mso: Yup.object()
      .nullable()
      .when("businessType", {
        is: (val) =>
          val && (val == "LOCAL_CABLE_OPERATOR" || val == "DISTRIBUTOR") ? true : false,
        then: () =>
          Yup.object()
            .shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
            .nullable()
            .required("MSO is required"),
      }),
    // distributor: Yup.object()
    //   .nullable()
    //   .when("businessType", {
    //     is: (val) => (val && val == "LOCAL_CABLE_OPERATOR" ? true : false),
    //     then: () =>
    //       Yup.object()
    //         .shape({
    //           label: Yup.string().required(),
    //           value: Yup.string().required(),
    //         })
    //         .nullable()
    //         .required("Distributor is required"),
    //   }),
    planGroup: Yup.object()
      .nullable()
      .when("businessType", {
        is: (val) => (val && val == "LOCAL_CABLE_OPERATOR" ? true : false),
        then: () =>
          Yup.object()
            .shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
            .nullable()
            .required("Sharing group is required"),
      }),
    keyId: Yup.string()
      .when("paymentGateway", {
        is: (val) => val?.label === "RazorPay", // Use optional chaining to handle undefined or null values
        then: () => Yup.string().required("Razor pay key id is required"),
        otherwise: () => Yup.string().notRequired(), // Make it optional if not RazorPay
      })
      .required("Razor pay key id is required only for RazorPay"),
    keyValue: Yup.string()
      .when("paymentGateway", {
        is: (val) => val?.label === "RazorPay", // Use optional chaining to handle undefined or null values
        then: () => Yup.string().required("Razor pay key value is required"),
        otherwise: () => Yup.string().notRequired(), // Make it optional if not RazorPay
      })
      .required("Razor pay key value is required only for RazorPay"),
    webHookSecret: Yup.string()
      .when("paymentGateway", {
        is: (val) => val?.label === "RazorPay", // Use optional chaining to handle undefined or null values
        then: () => Yup.string().required("Razor pay key id is required"),
        otherwise: () => Yup.string().notRequired(), // Make it optional if not RazorPay
      })
      .required("Razor pay webhook secret is required only for RazorPay"),
  },
  // [["password", "password"]],
);

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function EditTenant() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //const dispatch = useFormDispatch();
  const classes = useTabStyles();

  const { state: formState } = useFormState();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    let errorTabs = [];

    for (const [validationKey, fields] of Object.entries(errors)) {
      for (const [key, tablField] of Object.entries(tabFields)) {
        if (tablField.indexOf(validationKey) !== -1) {
          errorTabs.push(Number(key));
        }
      }
    }
    const latestTab = Math.min(...errorTabs);

    if (latestTab !== Infinity) {
      setTabValue(latestTab);
    }
  }, [errors]);

  const { getTenant, tenant, updateTenant } = useTenant();

  console.log(errors, "errorserrors12222");

  React.useEffect(() => {
    console.log(tenant, " values: formValues,");

    if (Object.keys(tenant).length !== 0) {
      const initialValues = {
        tenantName: tenant.tenantName,
        tenantId: tenant.tenantId,
        mobileNo: tenant.mobileNo,
        emailId: tenant.emailId,
        userName: tenant.userName,
        officialMobileNo: tenant.officialMobileNo,
        officialEmailId: tenant.officialEmailId,
        gstNo: tenant.gstNo,
        businessType: tenant.businessType,
        doorNo: tenant.doorNo,
        streetName: tenant.streetName,
        pinCode: tenant.pinCode,
        talukId: tenant.taluk?.talukId,
        townName: tenant.townName,
        districtId: tenant.district?.districtId,
        stateId: tenant.state?.stateId,
        countryId: tenant.country?.countryId,
        villageName: tenant.villageName,
        mso: {
          value: tenant.mso?.tenantId,
          label: tenant.mso?.tenantName,
        },
        distributor: {
          value: tenant.distributor?.tenantId,
          label: tenant.distributor?.tenantName,
        },
        ipTV:
          tenant.ipTv == null
            ? null
            : {
                value: tenant.ipTv?.ipTvId,
                label: tenant.ipTv?.ipTvName,
              },
        firstName: tenant.firstName,
        lastName: tenant.lastName,
        sharingType: tenant.sharingType,
        // distSharing: tenant.distSharing,
        // msoSharing: tenant.msoSharing,
        // lcoSharing: tenant.lcoSharing,
        status: tenant.status,
      };
      if (tenant.planGroup != null) {
        initialValues["planGroup"] = {
          value: tenant.planGroup?.planGroupId,
          label: tenant.planGroup?.planGroupName,
        };
      }
      if (tenant.paymentGateway != null) {
        initialValues["paymentGateway"] = {
          value: tenant.paymentGateway?.paymentGatewayId,
          label: tenant.paymentGateway?.paymentGatewayName,
        };
        if (tenant.paymentGateway?.paymentGatewayName == "RazorPay") {
          initialValues["keyId"] = tenant.keyId;
          initialValues["keyValue"] = tenant.keyValue;
          initialValues["webHookSecret"] = tenant.webHookSecret;
        }
      }
      reset(initialValues);
    }
  }, [tenant]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { state } = useLocation();
  console.log(state, "location");

  // React.useEffect(() => {
  //   if (form.current) {
  //     const { values, errors } = form.current;
  //     console.log(errors, "i am here to update values");
  //     dispatch({
  //       type: "UPDATE_FORM",
  //       payload: {
  //         id: "tenant",
  //         data: { values, errors },
  //       },
  //     });
  //   }
  // }, [tabValue]);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      console.log(data, "datadatadatadata-----222");
      const msoDto = {
        tenantId: data?.mso?.value,
      };
      const distributorDto = {
        tenantId: data?.distributor?.value,
      };
      data["mso"] = msoDto;

      data["taluk"] = {
        talukId: data?.talukId,
      };
      data["district"] = {
        districtId: data?.districtId,
      };
      data["state"] = {
        stateId: data?.stateId,
      };
      data["country"] = {
        countryId: data?.countryId,
      };
      data["distributor"] = distributorDto;
      console.log(data.iptv, "data?.distributor?.value");
      if (msoDto.tenantId == undefined) {
        delete data["mso"];
      }
      if (distributorDto.tenantId == undefined) {
        delete data["distributor"];
      }

      if (data.planGroup != undefined) {
        data["planGroup"] = {
          planGroupId: data?.planGroup?.value,
          planGroupName: data?.planGroup?.label,
        };
      }

      if (data.ipTV != null && Object.keys(data.ipTV).length != 0) {
        data["ipTV"] = {
          ipTvId: data.iptv.value,
        };
      } else {
        delete data["ipTV"];
      }
      if (data.paymentGateway != null && Object.keys(data.paymentGateway).length != 0) {
        data["paymentGateway"] = {
          paymentGatewayId: data?.paymentGateway?.value,
          paymentGatewayName: data?.paymentGateway?.label,
        };
      } else {
        data["paymentGateway"] = null;
      }

      let payload = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != ""));

      console.log(payload, "datadatadatadata");
      await updateTenant(payload);
      dispatch(showSuccessSnackbar("Tenant updated successfully!"));
      cancelBtn();
      //reset();
    } catch (err) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      let messageDetails = [];
      if (err.errors != undefined) {
        let errors = err.errors;
        for (let error in errors) {
          messageDetails.push(errors[error].field + " " + errors[error].message);
        }
        dispatch(showErrorSnackbar(messageDetails.join(",")));
      } else {
        dispatch(showErrorSnackbar(err.message));
      }
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/tenant/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Tenant</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getTenant(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_franchise")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                Tenant added successfully!
              </Alert>
            )} */}

            {/* {errorSubmit && errorSubmit.submit && (
              <Alert severity="error" my={3}>
                {errorSubmit.submit}
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    classes={{ scroller: classes.scroller }}
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="tenant"
                    sx={{
                      justifyContent: "center",
                      width: {
                        xs: "100%", // Full width on mobile devices
                        sm: "500px", // Fixed width on small devices
                        md: "700px", // Wider on medium devices
                        lg: "900px", // Even wider on larger devices
                      },
                      minWidth: "300px", // Ensure a minimum width across all devices
                      maxWidth: "100%", // Ensure it doesn't overflow the container
                    }}
                  >
                    {" "}
                    <Tab label="Franchise Details" {...a11yProps(0)} />
                    <Tab label="Address" {...a11yProps(1)} />
                    {/* <Tab label="Login Details" {...a11yProps(2)} /> */}
                    <Tab label="Contact Details" {...a11yProps(2)} />
                    <Tab label="IpTv Settings" {...a11yProps(3)} />
                    {/* <Tab label="Plans" {...a11yProps(5)} /> */}
                    <Tab label="IP Pools" {...a11yProps(4)} />
                    <Tab label="IpTv Plans" {...a11yProps(5)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <TenantDetail fields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <TenantAddressDetail fields={validationSchema.fields} />
                  </TabPanel>
                  {/* <TabPanel value={tabValue} index={2}>
                    <LoginDetail fields={validationSchema.fields} disabledFields={["userName"]} />
                  </TabPanel> */}
                  <TabPanel value={tabValue} index={2}>
                    <ContactDetail fields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <IpTvSettings fields={validationSchema.fields} />
                  </TabPanel>
                  {/* <TabPanel value={tabValue} index={5}>
                    <AllowedPolicy tenantId={state.id} />
                  </TabPanel> */}
                  <TabPanel value={tabValue} index={4}>
                    <AllowedIppool tenantId={state?.id} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <AllowedIpTvPlan tenantId={state?.id} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditTenant;
