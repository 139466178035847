import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import CustomDataGrid from "./components/CustomDataGrid";
import useSessionHistory from "../../../hooks/useSessionHistory";
import { useDialog } from "../../../contexts/DialogContext";

function CardSessionHistory() {
  const {
    getAllSessionHistory,
    sessionHistories,
    paging,
    isLoading,
    downloadFile,
    isLoadingDownload,
  } = useSessionHistory();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "leadId",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllSessionHistory(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadFile(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  // const addlead = () => {
  //   navigate("/sessionHistories/add");
  // };
  // const editlead = (id) => {
  //   console.log(id);
  //   navigate("/sessionHistories/edit", { state: { id: id } });
  // };
  // const viewDetails = (id) => {
  //   console.log(id);
  //   navigate("/sessionHistories/details", { state: { id: id } });
  // };
  // const assignTo = (id) => {
  //   console.log(id);
  //   navigate("/sessionHistories/assign", { state: { id: id } });
  // };
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            // addPage={addlead}
            // editPage={editlead}
            refreshPage={refreshPage}
            downloadFile={exportFile}
            openDownloadDialog={null}
            id="radAcctId"
            data={sessionHistories ? sessionHistories : []}
            rowCount={paging.totalNumberOfRecords}
            paging={{
              page: paging.pageNumber,
              pageSize: paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            fetchData={fetchData}
            setPayload={setPayload}
            payload={payload}
            setFilterResetFunc={setFilterResetFunc}
            isLoadingDownload={isLoadingDownload}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CardSessionHistory;
