import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import EditDelete from "../../../components/EditDelete";
import { showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import ToolBar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useScheduleUser from "../../../hooks/useScheduleUser";
import CancelSchedule from "./CancelSchedule";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>User</Typography>
      <Typography>Schedule User</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

function ScheduleUser(props) {
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "desc",
      },
    ],
  };

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllScheduleUser, scheduleUsers, paging, downloadFile } = useScheduleUser();
  const { openDialog } = useDialog();
  const { width: mainContentWidth } = useMainContentDimensions();
  const [selectedRows, setSelectedRows] = useState([]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllScheduleUser(payload);
  };

  console.log(selectedRows, "seleecttttttt");

  const clickSchedule = (id) => {
    return openDialog(
      "cancel_schedule",
      "Cancel Schedule",
      <CancelSchedule
        key={getTimeStamp()}
        userId={id}
        refreshPage={refreshPage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />,
    );
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "radUser",
      headerName: "User Name",
      valueGetter: (params) => params?.userName,
      width: 150,
    },
    {
      field: "plan",
      headerName: "Plan",
      valueGetter: (params) => params?.planName,
      width: 150,
    },
    {
      field: "rate",
      headerName: "Rate",
      valueGetter: (params) => params?.rateName,
      width: 150,
    },
    {
      field: "scheduleDateStr",
      headerName: "Schedule Date",
      width: 150,
    },
    {
      field: "scheduleStatus",
      headerName: "Status",
      width: 150,
    },
    {
      field: "scheduleType",
      headerName: "Schedule Type",
      width: 150,
    },

    {
      field: "renewedDateStr",
      headerName: "Renewed Date",
      width: 150,
    },
  ];

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <ToolBar
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            cancelSchedulePage={clickSchedule}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {t("schedule_user_list")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={scheduleUsers}
            getRowId={(row) => row["scheduleUserId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedRows(newRowSelectionModel);
            }}
            rowSelectionModel={selectedRows}
            isRowSelectable={(params) =>
              params.row.scheduleStatus == "PENDING" && params.row.renewalType != "ONLINE"
            }
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ScheduleUser;
