import { useContext } from "react";

import { OtpContext } from "../contexts/OtpContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useOtp = () => {
  const context = useContext(OtpContext);

  if (!context) throw new Error("OtpContext must be placed within OtpProvider");

  return context;
};

export default useOtp;
